(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('PuntoODGCopyController',PuntoODGCopyController);

        PuntoODGCopyController.$inject = ['$uibModalInstance', '$stateParams', 'seduta', 'odg', 'puntiODG', 'toastr'];

    function PuntoODGCopyController($uibModalInstance, $stateParams, seduta, odg, puntiODG, toastr) {
        var vm = this;

        vm.numeroSeduta = $stateParams.numeroSeduta;
        vm.legislatura = seduta.legislatura;;
        vm.progressivoODG = odg.progressivoODG;
        vm.puntiODG = puntiODG;
        vm.title = "";
        vm.textToCopy = [];

        vm.clear = clear;
        vm.copyRichTextToClipboard = copyRichTextToClipboard;
        vm.generateRichTextToCopy = generateRichTextToCopy;

        generateTextToCopy();
        generateRichTextToCopy();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function generateTextToCopy() {
            vm.title = "Legislatura " + vm.legislatura 
                + " - Seduta n°" + vm.numeroSeduta 
                + " - Ordine del Giorno n°" + vm.progressivoODG;
            
            
            for (var i = 0; i < vm.puntiODG.length; i++) {
                vm.textToCopy[i] = {};
                // vm.textToCopy[i].text = vm.puntiODG[i].progressivoPuntoODG + ". " + vm.puntiODG[i].testoPuntoODG;

                if(vm.puntiODG[i].idAtto) {
                    //vm.textToCopy[i].textSplits = vm.puntiODG[i].testoPuntoODG.split(/ -(.*)/s);
                    vm.textToCopy[i].textSplits = [];
                    var stringToSplit = vm.puntiODG[i].testoPuntoODG;
                    if(stringToSplit.indexOf("-") === -1) {
                        vm.textToCopy[i].textSplits.push(stringToSplit);
                        vm.textToCopy[i].textSplits.push("");
                    } else {
                        vm.textToCopy[i].textSplits.push(stringToSplit.substr(0, stringToSplit.indexOf("-")));
                        vm.textToCopy[i].textSplits.push(stringToSplit.substr( stringToSplit.indexOf("-"), stringToSplit.length));
                    }
                    vm.textToCopy[i].attachedActUrl = "https://iterlegis.consiglio.regione.toscana.it/#/atto/" + vm.puntiODG[i].idAtto + "/";
                }
            }
        }

        function generateRichTextToCopy() {
            vm.richTextToCopy = "<ol> \n";

            for (var i = 0; i < vm.puntiODG.length; i++) {
                if(vm.textToCopy[i].attachedActUrl) {
                    vm.richTextToCopy = vm.richTextToCopy + "<li><a href=\"" + vm.textToCopy[i].attachedActUrl + "\">" 
                        + vm.textToCopy[i].textSplits[0] + "</a>" 
                        + vm.textToCopy[i].textSplits[1] + "</li> \n";
                }
                else {
                    vm.richTextToCopy = vm.richTextToCopy + "<li>" + vm.puntiODG[i].testoPuntoODG + "</li> \n";
                }
            }      
            vm.richTextToCopy = vm.richTextToCopy + "</ol> \n";
        }

        function copyRichTextToClipboard(textToCopy) {
            function listener(e) {
              e.clipboardData.setData("text/html", textToCopy);
              e.clipboardData.setData("text/plain", textToCopy);
              e.preventDefault();
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);

            toastr.success("Punti dell'ordine del giorno copiati nella clipboard!");
        }     

    }
})();