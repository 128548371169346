(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('StatoODG', StatoODG);

    StatoODG.$inject = ['$resource', 'DateUtils'];

    function StatoODG ($resource, DateUtils) {
        var resourceUrl =  'api/stato-odgs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataOraCambioStato = DateUtils.convertDateTimeFromServer(data.dataOraCambioStato);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
