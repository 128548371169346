(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('ListaDiDistribuzioneController', ListaDiDistribuzioneController);

        ListaDiDistribuzioneController.$inject = ['$state','toastr', 'pagingParams', 'paginationConstants', 'ParseLinks', 'ListaDiDistribuzione', '$translate'];

    function ListaDiDistribuzioneController($state, toastr, pagingParams, paginationConstants, ParseLinks, ListaDiDistribuzione, $translate) {
        var vm = this;
        vm.listaDiDistribuzioneList = [];
        //Search default
        vm.pagingParams = pagingParams || {};

         // query stuff
         vm.predicate = vm.pagingParams.predicate;
         vm.reverse = vm.pagingParams.ascending;
         vm.itemsPerPage = paginationConstants.itemsPerPage;

         vm.loading = false;


         loadAll();

         function loadAll() {
             vm.loading = true;
             ListaDiDistribuzione.queryPaged({
                page: vm.pagingParams.page - 1,
                sort: sort(),
                size: vm.itemsPerPage
            }, onSuccess, onError);
         }

         function onSuccess(data, headers) {
             vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.listaDiDistribuzioneList = data;
             vm.page = vm.pagingParams.page;
             vm.loading = false;
         }

         function onError(error) {
            if(error.status === 403){
                toastr.error($translate.instant('error.access.external.forbidden'));
            } else {
                console.log("Failure!", error);
                toastr.error(error.data.message);
                vm.loading = false;
            }
             
         }

         function sort() {
             var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
             if (vm.predicate !== 'id') {
                 result.push('id');
             }
             return result;
         }

        vm.transition = function() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }
    }
})();