(function () {
    'use strict';
    angular
            .module('gestioneAttiApp')
            .factory('Settore', Settore);

    Settore.$inject = ['$resource'];

    function Settore($resource) {
        var resourceUrl = 'api/:public/settores/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', params: {public: 'public'}, isArray: true},
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
