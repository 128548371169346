(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('ListaDiDistribuzioneDeleteController', ListaDiDistribuzioneDeleteController);

            ListaDiDistribuzioneDeleteController.$inject = ['$uibModalInstance', 'entity', 'ListaDiDistribuzione'];

    function ListaDiDistribuzioneDeleteController($uibModalInstance, entity, ListaDiDistribuzione) {
        var vm = this;

        vm.listaDiDistribuzione = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            ListaDiDistribuzione.delete({id: id},
                    function () {
                        $uibModalInstance.close(true);
                    });
        }
    }
})();
