(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('RegistrazioneUtenteController', RegistrazioneUtenteController);

        RegistrazioneUtenteController.$inject = ['$state', 'toastr', 'RegistrazioneUtente', 'ACT_TYPES'];

    function RegistrazioneUtenteController($state, toastr, RegistrazioneUtente, ACT_TYPES) {
        var vm = this;
        vm.mail = null;
        vm.selectedTipoAtto = [];
        vm.save = save;
        vm.actTypesPublished = ACT_TYPES;
        vm.isSavingConfig = false;

        function save() {
            vm.isSavingConfig = true;

            if(vm.mail) {
                var configToSave = {
                    mail: vm.mail,
                    tipoAttoList: vm.selectedTipoAtto
                }
                RegistrazioneUtente.createOrUpdate(configToSave, onConfigSaveSuccess, onConfigSaveError);
            }
        }

        function onConfigSaveSuccess(result) {
            vm.isSavingConfig = false;
            toastr.success('Salvataggio registrazione utente alle notifiche mail avvenuto con successo!');
        }

        function onConfigSaveError(error) {
            toastr.error('Errore durante il salvataggio della registrazione alle notifiche: inserire una mail valida!');
            vm.isSavingConfig = false;
        }

        vm.getTranslationString = function(item){
            return (ACT_TYPES.find(function(tipoAtto){return tipoAtto.value === item})).name;
        }
    }
})();
