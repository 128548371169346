(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('commissione', {
            parent: 'entity',
            url: '/commissione',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gestioneAttiApp.commissione.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commissione/commissiones.html',
                    controller: 'CommissioneController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('commissione');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('commissione-detail', {
            parent: 'commissione',
            url: '/commissione/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gestioneAttiApp.commissione.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commissione/commissione-detail.html',
                    controller: 'CommissioneDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('commissione');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Commissione', function($stateParams, Commissione) {
                    return Commissione.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'commissione',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('commissione-detail.edit', {
            parent: 'commissione-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commissione/commissione-dialog.html',
                    controller: 'CommissioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Commissione', function(Commissione) {
                            return Commissione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('commissione.new', {
            parent: 'commissione',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commissione/commissione-dialog.html',
                    controller: 'CommissioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('commissione', null, { reload: 'commissione' });
                }, function() {
                    $state.go('commissione');
                });
            }]
        })
        .state('commissione.edit', {
            parent: 'commissione',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commissione/commissione-dialog.html',
                    controller: 'CommissioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Commissione', function(Commissione) {
                            return Commissione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('commissione', null, { reload: 'commissione' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('commissione.delete', {
            parent: 'commissione',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commissione/commissione-delete-dialog.html',
                    controller: 'CommissioneDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Commissione', function(Commissione) {
                            return Commissione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('commissione', null, { reload: 'commissione' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
