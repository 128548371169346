(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('CommissioneDeleteController',CommissioneDeleteController);

    CommissioneDeleteController.$inject = ['$uibModalInstance', 'entity', 'Commissione'];

    function CommissioneDeleteController($uibModalInstance, entity, Commissione) {
        var vm = this;

        vm.commissione = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Commissione.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
