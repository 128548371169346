(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('MacroMateria', MacroMateria);

        MacroMateria.$inject = ['$resource', 'DateUtils'];

    function MacroMateria ($resource, DateUtils) {
        var resourceUrl =  'api/:public/macro-materie';

        var resource = $resource(resourceUrl, {}, {
            'queryPaged': {
                method: 'GET',
                isArray: true,
                url: resourceUrl + '/paged'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                url: resourceUrl + '/:id'
            },
            'delete': {method: 'DELETE', url: resourceUrl + '/:id'},
            'query': {
                method: 'GET',
                isArray: true,
                url: resourceUrl
            },
            'save': {
                method: 'POST',
                url: resourceUrl,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validityStartDate = DateUtils.convertLocalDateToServer(data.validityStartDate);
                    copy.validityEndDate = DateUtils.convertLocalDateToServer(data.validityEndDate);
                    return angular.toJson(copy);
                }
            },
            'update': {
                method: 'PUT',
                url: resourceUrl,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.validityStartDate = DateUtils.convertLocalDateToServer(data.validityStartDate);
                    copy.validityEndDate = DateUtils.convertLocalDateToServer(data.validityEndDate);
                    return angular.toJson(copy);
                }
            }
        });

        return resource;
    }
})();