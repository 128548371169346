(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('MateriaDialogController', MateriaDialogController);

            MateriaDialogController.$inject = ['$timeout', '$scope', 'toastr', '$mdDialog', 'entity', 'Materia', '$state'];

    function MateriaDialogController($timeout, $scope, toastr, $mdDialog, entity, Materia, $state) {
        var vm = this;
        vm.materia = vm.entity;
        vm.clear = clear;
        vm.save = save;
        vm.startMinDate = null;
        vm.startMaxDate = null;
        vm.endMinDate = null;
        vm.endMaxDate = null;
        vm.macroMateriaList = vm.macroMateriaList;

        if (vm.entity && vm.entity.id) {
            updateEndMinMaxDate();
            updateStartMinMaxDate();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.isFormValid = function() {
            return vm.materia.code && vm.materia.description && vm.materia.validityStartDate;
        }
        vm.startDateChanged = function() {
            if (vm.materia.validityStartDate && vm.materia.validityEndDate && vm.materia.validityStartDate >= vm.materia.validityEndDate ) {
                vm.materia.validityEndDate = null;
            }
            updateEndMinMaxDate(); 
        }
        function clear() {
            $mdDialog.cancel();
        }

        function save() {
            vm.isSaving = true;
           if (vm.materia.id) {
                Materia.update(vm.materia, onSaveSuccess, onSaveError);
            } else {
                Materia.save(vm.materia, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gestioneAttiApp:materiaUpdate', result);
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('materia', null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.macroMateriaChanged = function(macroMateria) {
            if (!macroMateria) {
                vm.materia.validityStartDate = null;
                vm.materia.validityEndDate = null;
            } else {
                var newMacroMateriaSelected = macroMateria;
                vm.materia.validityStartDate = new Date(newMacroMateriaSelected.validityStartDate);
                vm.materia.validityEndDate = newMacroMateriaSelected.validityEndDate ? new Date(newMacroMateriaSelected.validityEndDate) : null;
                vm.materia.macroMateria = newMacroMateriaSelected;
                updateStartMinMaxDate();
                updateEndMinMaxDate();
            }
        }

        function updateStartMinMaxDate() {
            
            if (vm.materia.macroMateria) {
                if (vm.materia.macroMateria.validityEndDate) {
                    vm.startMaxDate = new Date(vm.materia.macroMateria.validityEndDate);
                    vm.startMaxDate.setDate(vm.startMaxDate.getDate() - 1);
                } else {
                    vm.startMaxDate = null;
                }
                vm.startMinDate = new Date(vm.materia.macroMateria.validityStartDate);
            }
        }

        function updateEndMinMaxDate() {
            vm.endMinDate = new Date(vm.materia.validityStartDate);
            vm.endMinDate.setDate(vm.endMinDate.getDate() + 1);
            if (vm.materia.macroMateria) {
                vm.endMaxDate =  vm.materia.macroMateria.validityEndDate ? new Date(vm.materia.macroMateria.validityEndDate) : null;
            }
        }
    }
})();
