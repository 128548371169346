(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('CommissioneRuoloDialogController', CommissioneRuoloDialogController);

    CommissioneRuoloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CommissioneRuolo'];

    function CommissioneRuoloDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CommissioneRuolo) {
        var vm = this;

        vm.commissioneRuolo = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.commissioneRuolo.id !== null) {
                CommissioneRuolo.update(vm.commissioneRuolo, onSaveSuccess, onSaveError);
            } else {
                CommissioneRuolo.save(vm.commissioneRuolo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gestioneAttiApp:commissioneRuoloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
