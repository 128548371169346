(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('RegistrazioneUtenteDialogController', RegistrazioneUtenteDialogController);

        RegistrazioneUtenteDialogController.$inject = ['$state', 'toastr', '$mdDialog', 'attoId', 'RegistrazioneUtente', '$stateParams', 'ACT_TYPES'];

    function RegistrazioneUtenteDialogController($state, toastr, $mdDialog, attoId, RegistrazioneUtente, $stateParams, ACT_TYPES) {
        var vm = this;
        vm.mail = null;
        vm.attoId = attoId;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.atto = $stateParams.atto;
        vm.attoTypeName = ACT_TYPES[ACT_TYPES.findIndex(function(tipoAtto) {return tipoAtto.value === vm.atto.tipoAtto;})].name;

        function clear() {
            $mdDialog.cancel();
            $state.go('home', null, {reload: true});
        }

        function save() {
            vm.isSaving = true;

            if(vm.mail) {
                var configToSave = {
                    mail: vm.mail,
                    attoId: vm.attoId
                }
                RegistrazioneUtente.createOrUpdate(configToSave, onConfigSaveSuccess, onConfigSaveError);
            }
        }

        function onConfigSaveSuccess(result) {
            vm.isSaving = false;
            toastr.success('Salvataggio registrazione utente alle notifiche mail avvenuto con successo!');
            vm.clear();
        }

        function onConfigSaveError() {
            toastr.error('Errore durante il salvataggio della registrazione utente alle notifiche mail');
            vm.isSaving = false;
        }
    }
})();
