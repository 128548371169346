(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('PuntoODGDialogController', PuntoODGDialogController);

    PuntoODGDialogController.$inject = ['$timeout', '$scope', '$state', 'editingMode', '$mdDialog', 'toastr', 'entity', 'odg', 'seduta', 'workingBody', 'title', 'attoCollegato', 'PuntoODG', 'Atto', 'ACT_TYPES', 'SedutaCommonService', 'editODG'];

    function PuntoODGDialogController ($timeout, $scope, $state, editingMode, $mdDialog, toastr, entity, odg, seduta, workingBody, title, attoCollegato, PuntoODG, Atto, ACT_TYPES, SedutaCommonService, editODG) {
        var vm = this;

        vm.editODG = editODG;
        vm.workingBody = workingBody;
        vm.puntoODG = entity;
        vm.editingMode = editingMode;
        vm.odg = odg;
        vm.seduta = seduta;
        vm.title = title;
        vm.attoCollegato = attoCollegato;

        vm.numeroAttoSearchText = '';
        vm.attachedActsModel = [];
        vm.attachedActsReadOnlyListModel = [];
        vm.searchActs = searchActs;
        vm.getAllActs = getAllActs;
        vm.transformChip = transformChip;
        vm.completeFormByAct = completeFormByAct;
        vm.clearForm = clearForm;
        vm.clear = clear;
        vm.save = save;

        vm.acts = [];
        vm.actsTypeSelection = [];

        ACT_TYPES.forEach(function (actType) {
            vm.actsTypeSelection.push(actType);
        });
        vm.basicSearchType = attoCollegato ? attoCollegato.tipoAtto : null;
        vm.selectedAtto = vm.attoCollegato;

        getAllActs();

        calcolaProgressivoPuntoODG();
        
        function calcolaProgressivoPuntoODG() {
            if(vm.puntoODG.id === null) {
                if(vm.odg.idPuntiODG !== null) {
                    vm.puntoODG.progressivoPuntoODG = vm.odg.idPuntiODG.length + 1;
                }
                else vm.puntoODG.progressivoPuntoODG = 1;
            }
        }
        
        function clear () {
            $mdDialog.cancel();
            if(vm.editODG){
                $state.go('ordine-del-giorno.edit');
            } else {
                $state.go('ordine-del-giorno');
            }
        }

        function save () {
            if(vm.puntoODG.tipoEspressioneRichiesta === "") {
                vm.puntoODG = {
                    tipoPuntoODG: vm.puntoODG.tipoPuntoODG,
                    testoPuntoODG: vm.puntoODG.testoPuntoODG,
                    idAtto: vm.puntoODG.idAtto,
                    progressivoPuntoODG: vm.puntoODG.progressivoPuntoODG,
                    esitoPuntoODG: vm.puntoODG.esitoPuntoODG,
                    descrizioneEsitoPuntoODG: vm.puntoODG.descrizioneEsitoPuntoODG
                }
            }
            vm.isSaving = true;

            if(vm.puntoODG.id !== null) {
                vm.puntoODG = PuntoODG.update({idODG: vm.odg.id, idSeduta:vm.seduta.id}, vm.puntoODG, onSaveSuccess, onSaveError);
            } else {
                vm.puntoODG = PuntoODG.save({idODG: vm.odg.id}, vm.puntoODG, onSaveSuccess, onSaveError);
            }
        }

        function searchActs(tokenNumeroAtto) {
            var results = vm.acts;
            return tokenNumeroAtto ? results.filter(createFilterFor(tokenNumeroAtto)) : [];
        }

        function createFilterFor(tokenNumeroAtto) {
            return function filterFn(atto) {
                var numeroAtto = atto.numeroAtto;
                return (numeroAtto.includes(tokenNumeroAtto));
            };
        }

        function transformChip($chip) {
            //Qui scegliere cosa tenere dentro la chip!
            return { "id": $chip.id, "legislatura": $chip.legislatura, "numeroAtto": $chip.numeroAtto, "titolo": $chip.titolo, "tipoAtto": $chip.tipoAtto, "proponente": $chip.proponente};
        }

        function getAllActs () {
            vm.acts = [];
            if (!vm.basicSearchType) {
                return;
            }
            Atto.queryNonPaged({type: vm.basicSearchType, legislaturaSearch: vm.seduta.legislatura}, onSuccess, onError);
            function onSuccess(data) {
                vm.acts = data;
            };
            function onError(error) {
                console.log(error);
            }
        }

        function completeFormByAct($chip) {   
            vm.puntoODG.idAtto = $chip.id;

            var proponentiString = "";

            if($chip.proponente != null && $chip.proponente.proponenti != null) {
                $chip.proponente.proponenti.forEach(function (proponente) {
                    proponentiString = proponentiString + capitalizeFirstLetter(proponente.descrizione) + ", ";
                });
            }
            
            // vm.puntoODG.testoPuntoODG = translateTipoAtto($chip.tipoAtto) + " n." + $chip.numeroAtto + " - " + $chip.titolo + 
            //     " - proponenti: " + proponentiString.slice(0,-2);

            vm.puntoODG.testoPuntoODG = $chip.titolo + 
                 " - proponenti: " + proponentiString.slice(0,-2);
            
            
            Atto.getSezioneByIdAttoAndNomeSezione($chip.id, "ASSIGNMENT_LETTER").then(
                function onSuccess(data) {
                vm.assignmentLetter = data;
                }, 
                function onError(error) {
                    console.log(error);
                });

            setTimeout(function() {
                if(vm.assignmentLetter && vm.assignmentLetter.metadati) {
                    vm.puntoODG.tipoEspressioneRichiesta = getTipoEspressioneRichiesta(vm.assignmentLetter.metadati);               
                }
            },100);
        }

        function capitalizeFirstLetter(string) {
            const arr = string.split(" ");

            for (var i = 0; i < arr.length; i++) {
                arr[i] = angular.uppercase(arr[i].charAt(0)) + angular.lowercase(arr[i].slice(1));
            }

           return arr.join(" ");
        }

        function getTipoEspressioneRichiesta(metadati) {
            if(vm.assignmentLetter.metadati.parereReferente && metadati.parereReferente.length > 0) {
                var parereReferente = vm.assignmentLetter.metadati.parereReferente.indexOf(vm.workingBody.id);
                if(parereReferente >= 0 && metadati.parereReferente.length > 1) {
                    return "PARERE_REF_CONG";
                }
                else if(parereReferente >= 0) {
                    return "PARERE_REF";
                }
            } 
            if(vm.assignmentLetter.metadati.parereSecondario && metadati.parereSecondario.length > 0) {
                var parereSecondario = vm.assignmentLetter.metadati.parereSecondario.indexOf(vm.workingBody.id);
                if(parereSecondario >= 0) {
                    return "PARERE_SEC";
                }
            }
            if(vm.assignmentLetter.metadati.parereDiMerito && metadati.parereDiMerito.length > 0) {
                var parereDiMerito = vm.assignmentLetter.metadati.parereDiMerito.indexOf(vm.workingBody.id);
                if(parereDiMerito >= 0) {
                    return "PARERE_MER";
                }
            }
            if(vm.assignmentLetter.metadati.parereObbligatorio && metadati.parereObbligatorio.length > 0) {
                var parereObbligatorio = vm.assignmentLetter.metadati.parereObbligatorio.indexOf(vm.workingBody.id);
                if(parereObbligatorio >= 0) {
                    return "PARERE_OBB";
                }
            }
            if(vm.assignmentLetter.metadati.osservazioni && metadati.osservazioni.length > 0) {
                var osservazioni = vm.assignmentLetter.metadati.osservazioni.indexOf(vm.workingBody.id);
                if(osservazioni >= 0) {
                    return "OSSERVAZIONI";
                }
            }
            
        }

        function clearForm() {
            vm.puntoODG.testoPuntoODG = null;
            vm.puntoODG.tipoEspressioneRichiesta = null;
            vm.puntoODG.idAtto = null;
        }

        function onSaveSuccess (result) {
            toastr.success('Punto ODG salvato con successo!');
            $mdDialog.cancel();
            vm.isSaving = false;
            $state.go('ordine-del-giorno.edit', {odg: vm.odg, seduta: vm.seduta}, { reload: true });
        }

        function onSaveError (error) {
            toastr.error('Errore nel salvataggio del Punto ODG');
            console.log(error);
            vm.isSaving = false;
        }

        function translateTipoAtto(tipoAtto) {
            if(tipoAtto === "PDL") {
                return "Proposta di legge";
            }
            if(tipoAtto === "PDD") {
                return "Proposta di delibera";
            }
            if(tipoAtto === "PLIP") {
                return "Proposta di legge di iniziativa popolare";
            }
            if(tipoAtto === "PDLP") {
                return "Proposta di legge al parlamento";
            }
            if(tipoAtto === "PDLS") {
                return "Proposta di legge statutaria";
            }
            if(tipoAtto === "PDR") {
                return "Proposta di risoluzione";
            }
            if(tipoAtto === "ODG") {
                return "Ordine del giorno";
            }
            if(tipoAtto === "MOZIONE") {
                return "Mozione";
            }
            if(tipoAtto === "ODG") {
                return "Ordine del giorno";
            }
            if(tipoAtto === "PEA") {
                return "Pareri ex articolo 42";
            }
            if(tipoAtto === "IO") {
                return "Interrogazione orale";
            }
            if(tipoAtto === "IS") {
                return "Interrogazione scritta";
            }
            if(tipoAtto === "CDG") {
                return "Comunicazione di Giunta";
            }
            if(tipoAtto === "PEL") {
                return "Pareri ex lege";
            }
            if(tipoAtto === "IEA") {
                return "Informativa ex articolo 48";
            }
            if(tipoAtto === "REL") {
                return "Relazioni organismi esterni";
            }
            if(tipoAtto === "DEA") {
                return "Determinazione ex art 50";
            }
            if(tipoAtto === "PDRIS") {
                return "Proposta di risoluzione";
            }
            if(tipoAtto === "RINT") {
                return "Regolamenti interni";
            }
        }

        vm.isFormValid = function() {
            return vm.puntoODG.tipoPuntoODG && vm.puntoODG.testoPuntoODG;
        }

        vm.hasEsito = function()  {
            return vm.puntoODG.tipoPuntoODG && vm.puntoODG.tipoPuntoODG === 'E';
        }

        vm.canEdit = function() {
            return vm.editingMode && SedutaCommonService.isOrganizzatore(vm.seduta, vm.workingBody);
        }
    }
})();
