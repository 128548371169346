(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SedutaController', SedutaController);

    SedutaController.$inject = ['$scope','$state', 'Seduta', 'OrdineDelGiorno', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'workingBody', 'legislature', 'SedutaCommonService', '$filter', '$timeout'];

    function SedutaController($scope, $state, Seduta, OrdineDelGiorno, ParseLinks, AlertService, paginationConstants, pagingParams, workingBody, legislature, SedutaCommonService, $filter, $timeout) {

        var vm = this;

        vm.sedutas = [];
        vm.odgSedute = {};
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.legislatura = pagingParams.legislatura;
        vm.years = [];
        vm.anno = pagingParams.anno;
        vm.legislature = legislature;
        vm.minNum = pagingParams.minNum;
        vm.maxNum = pagingParams.maxNum;
        vm.minDataConv = pagingParams.minDataConv;
        vm.maxDataConv = pagingParams.maxDataConv;
        vm.testoOdg = pagingParams.testoOdg;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.workingBody = workingBody;
        
        vm.canEdit = canEdit;
        vm.clearMinDataConvocazione = clearMinDataConvocazione;
        vm.clearMaxDataConvocazione = clearMaxDataConvocazione;

        loadAll();

        function loadAll () {

            var currentYear = new Date().getFullYear();
            for (var year = currentYear + 1; year >= 2022; year--) {
                vm.years.push(year);
            }

            Seduta.organismiUtente({}, function(result) {
                vm.workingBody = result.filter(filterWorkingBody)[0];
            });

            Seduta.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                legislatura: pagingParams.legislatura,
                anno: vm.anno,
                minNum: pagingParams.minNum,
                maxNum: pagingParams.maxNum,
                minDataConv: vm.minDataConv,
                maxDataConv: vm.maxDataConv,
                testoOdg: pagingParams.testoOdg,
                typeOrg: vm.workingBody.tipoOrganismo,
                idOrg: vm.workingBody.id
            }, onSuccessSeduta, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccessSeduta(data, headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                var sedute = data;
                
                for(var i = 0; i < sedute.length; i++) {
                    var seduta = sedute[i];
                    seduta.numeroSeduta = SedutaCommonService.getNumeroSedutaOfWorkingBody(seduta, vm.workingBody);
                    seduta.isOrganizzatore = SedutaCommonService.isOrganizzatore(seduta, vm.workingBody);
                    seduta.partecipantiSeduta =  SedutaCommonService.getPartecipantiSeduta(seduta);
                    seduta.organizzatoreSeduta = SedutaCommonService.getOrganizzatoreSeduta(seduta);
                }

                sedute.forEach(function(seduta){
                    OrdineDelGiorno.getAllByIdSeduta(
                        {idSeduta: seduta.id},
                        function (data) {vm.odgSedute[seduta.id] = data;},
                        onError);
                });

                vm.page = pagingParams.page;

                var ordered = $filter('orderBy')(sedute, vm.predicate, !vm.reverse);
                var start = (vm.page - 1) * vm.itemsPerPage;
                var end = start + vm.itemsPerPage;
                vm.sedutas = ordered.slice(start, end);

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition(resetPage) {

            if(resetPage) {
                vm.page = 1;
            }
            
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                idOrg: vm.workingBody.id,
                typeOrg: vm.workingBody.tipoOrganismo,
                nameOrg: vm.workingBody.nome,
                legislatura: vm.legislatura,
                anno: vm.anno,
                minNum: vm.minNum,
                maxNum: vm.maxNum,
                minDataConv: getFormattedDate(vm.minDataConv),
                maxDataConv: getFormattedDate(vm.maxDataConv),
                testoOdg: vm.testoOdg

            });
        }

        function getFormattedDate(date) {
            if (!date) {
                return null;
            }
            try {
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if (month < 10) {
                    month = '0' + month;
                }
                var day = date.getDate();
                if (day < 10) {
                    day = '0' + day;
                }              
                return year + '-' + month + '-' + day
            } catch (err) {
                return date;
            }
        }

        // Filter for organismi that are the current working body (to get details not in stateParams)
        function filterWorkingBody(element){
            return element.id === vm.workingBody.id && element.tipoOrganismo === vm.workingBody.tipoOrganismo;
        }

        function clearMinDataConvocazione(){
            vm.minDataConv = null;
            vm.transition(true);
        }

        function clearMaxDataConvocazione(){
            vm.maxDataConv = null;
            vm.transition(true);
        }

        // $scope.$watch('vm.sedutas', function(sedute) {
        //     for(var i = 0; i < sedute.length; i++) {
        //       var seduta = sedute[i];
        //       seduta.numeroSeduta = SedutaCommonService.getNumeroSedutaOfWorkingBody(seduta, vm.workingBody);
        //       seduta.isOrganizzatore = SedutaCommonService.isOrganizzatore(seduta, vm.workingBody);
        //       seduta.partecipantiSeduta =  SedutaCommonService.getPartecipantiSeduta(seduta);
        //       seduta.organizzatoreSeduta = SedutaCommonService.getOrganizzatoreSeduta(seduta);
        //     }

        //  });

        function canEdit(seduta){
            return SedutaCommonService.canEditSeduta(seduta, vm.workingBody);
        }

    }
})();
