(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('ListaDiDistribuzioneDialogController', ListaDiDistribuzioneDialogController);

            ListaDiDistribuzioneDialogController.$inject = ['$timeout', '$scope', 'toastr', '$mdDialog', 'entity', 'ListaDiDistribuzione', '$state'];

    function ListaDiDistribuzioneDialogController($timeout, $scope, toastr, $mdDialog, entity, ListaDiDistribuzione, $state ) {
        var vm = this;
        vm.listaDiDistribuzione = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $mdDialog.cancel();
        }

        function save() {
            vm.isSaving = true;
            if(!vm.listaDiDistribuzione.id) {
                ListaDiDistribuzione.create(vm.listaDiDistribuzione, onSaveSuccess, onSaveError);
            } else {
                ListaDiDistribuzione.update(vm.listaDiDistribuzione.id, vm.listaDiDistribuzione, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gestioneAttiApp:listaDiDistribuzioneUpdate', result);
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('lista-di-distribuzione', null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
