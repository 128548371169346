(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('OrdineDelGiornoCreateController', OrdineDelGiornoCreateController);

    OrdineDelGiornoCreateController.$inject = ['toastr', '$scope', '$state', 'workingBody', 'seduta', 'OrdineDelGiorno', 'Commissione', 'SoggettoEsterno'];

    function OrdineDelGiornoCreateController (toastr, $scope, $state, workingBody, seduta, OrdineDelGiorno, Commissione, SoggettoEsterno) {
        var vm = this;

        vm.workingBody = workingBody;
        vm.seduta = seduta;
        vm.organizzatoreSeduta = {};
        vm.odg = {};
        vm.odg.idSeduta = seduta.id;

        vm.save = save;

        findOrganizzatore(vm.seduta.membriSeduta);
        calcolaProgressivoODG();
        toTipoSeduta();
        
        function findOrganizzatore(membriSeduta) {
            membriSeduta.forEach(function(membro) {
                if(membro.isOrganizzatore) {
                    vm.organizzatoreSeduta.numeroSeduta = membro.numeroSeduta;
                    if(membro.tipoMembro == "C") {
                        Commissione.get({id: membro.idMembro}, {}, function(result) {
                            vm.organizzatoreSeduta.nome = result.nome;
                            // Inizializzazione oggetto ODG
                            vm.odg.oggettoODG = "Convocazione seduta " + result.nome;
                        });           
                    }
                    else if (membro.tipoMembro == "E") {
                        SoggettoEsterno.get({id: membro.idMembro}, {}, function(result) {
                            vm.organizzatoreSeduta.nome = result.nome;
                        });
                    } 
                }
            });
        }

        function toTipoSeduta() {
            if(vm.seduta.tipoSeduta == 'N') {
                vm.tipoSeduta = "Ordinaria"
            }
            else if(vm.seduta.tipoSeduta == 'C') {
                vm.tipoSeduta = "Congiunta"
            }
        }

        function calcolaProgressivoODG() {
            OrdineDelGiorno.getAllByIdSeduta(
                {idSeduta: vm.seduta.id}, 
                function (data) {if(data) {vm.odg.progressivoODG = data.length + 1;}}, 
                function (error) {toastr.error("Errore: ", error.data.message);}
                );
        }

        function save () {
            vm.isSaving = true;
            if (vm.odg.id !== null) {
                OrdineDelGiorno.update(vm.odg, onSaveSuccess, onSaveError);
            } else {
                vm.odg.statoCorrenteODG.statoODG = "A";
                vm.odg = OrdineDelGiorno.save(vm.odg, onSaveSuccess, onSaveError);
            }
            $state.go('seduta', {
                idOrg: vm.workingBody.id, 
                typeOrg: vm.workingBody.tipoOrganismo, 
                nome: vm.workingBody.nameOrg
            }, {reload: true});
        }

        function onSaveSuccess (result) {
            $scope.$emit('gestioneAttiApp:ordineDelGiornoUpdate', result);
            toastr.success('Ordine del Giorno salvato con successo!');
            vm.dettaglioVisibile = true;
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            toastr.error('Errore durante il salvataggio dell\'\Ordine del Giorno');
        }

        vm.isFirstFormValid = function() {
            return vm.odg.tipoODG && vm.odg.oggettoODG;
        }
    }
})();
