(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('Seduta', Seduta);

    Seduta.$inject = ['$resource', 'Upload', 'DateUtils', '$http'];

    function Seduta ($resource, Upload, DateUtils, $http) {
        var resourceUrl =  'api/sedutas/:id/:typeOrg/:idOrg/:organismiUtente/:numero/:transizione/:nextStateName/:puntiAffariTrattati/:ordineDelGiorno/:idOdgDoc/:files/:protocolNow/:filingcode';

        var resource = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataDiConvocazione = DateUtils.convertDateTimeFromServer(data.dataDiConvocazione);
                    }
                    return data;
                }
            },
            'saveWithWB' : {
                method: 'POST'
            },
            'update': { method:'PUT' },
            'organismiUtente' : {
                method: 'GET',
                params: {organismiUtente: 'organismiUtente'},
                isArray: true
            },
            'organismiSeduta' : {
                method: 'GET',
                params: {organismiUtente: 'organismi'},
                isArray: true
            },
            'nextNumeroSeduta' : {
                method: 'GET',
                params: {
                    numero : 'numero'
                }
            },
            'sedutaStateTransition' : {
                method : 'PUT',
                params : {
                    transizione: 'transizione'
                }
            },
            'getPuntiAffariTrattatiSeduta': {
                method: 'GET',
                params : {
                    puntiAffariTrattati: 'punti-affari-trattati'
                },
                isArray : true
            },
            'getSedutaDocuments': {
                method: 'GET',
                params : {
                    files: 'files'                    
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    var result = [];
                    var lastGroup = null;
                    if (data && (!data.status || data.status === 200)) {
                        angular.forEach(data, function (document) {
                            if (lastGroup === null || lastGroup.name !== document.gruppoProtocollo) {
                                lastGroup = {
                                    name: document.gruppoProtocollo,
                                    protocolState: document.statoProtocollo,
                                    mittenti: document.mittentiProtocollo || [],
                                    destinatari: document.destinatariProtocollo || [],
                                    documents: [],
                                    operatingOffice: document.operatingOffice
                                };
                                result.push(lastGroup);
                            }
                            document.name = document.nome;
                            document.public = document.pubblico;
                            document.frontpiece = document.frontespizio;
                            document.protocolState = document.statoProtocollo;
                            document.protocolGroup = document.gruppoProtocollo;
                            document.docType = document.tipoDocumento.tipo;
                            lastGroup.documents.push(document);
                        });
                    }
                    return { data: result };
                }
            },
            'protocolNow': {
                method: 'POST',
                params: {
                    ordineDelGiorno: 'ordine-del-giorno',
                    files: 'files',
                    protocolNow: 'protocolNow'
                },
                isArray: true
            },
            'getFilingCode': {
                method: 'GET',
                params: {
                    filingcode: 'filingcode',
                },
                transformResponse: function (data) {
                    return { data: data };
                }
            },
        });

        resource.uploadFileSeduta = function (idSeduta, file, params) {
            return Upload.upload({
                url: 'api/sedutas/' + idSeduta + '/files',
                method: 'POST',
                data: params || {},
                file: file,
            })
        };

        resource.updateFileProtocolData = function (idSeduta, idOdgPar, fileList) {
            var params = {idOdg : idOdgPar};
            return $http.put('api/sedutas/' + idSeduta + '/files', fileList, params).then(
                function (response) {
                    // success callback
                    console.log("Success: ", response);
                },
                function (response) {
                    // failure callback
                    console.log("Failure: ", response);
                });
        };

        resource.getAffariTrattatiPDFReport = function (idSeduta) {
            return $http.get('/api/sedutas/' + idSeduta + '/affariTrattatiReport',
                { responseType: 'arraybuffer' });
        };

        return resource;
    }
})();
