(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SedutaDialogController', SedutaDialogController);

    SedutaDialogController.$inject = ['$state', 'toastr', '$timeout', '$mdDialog', 'entity', 'Seduta', 'workingBody', 'nextNumeroSeduta', 'organismiSeduta', 'Commissione', 'SoggettoEsterno','SedutaCommonService', 'anno'];

    function SedutaDialogController ($state, toastr, $timeout, $mdDialog, entity, Seduta, workingBody, nextNumeroSeduta, organismiSeduta, Commissione, SoggettoEsterno, SedutaCommonService, anno) {
        var vm = this;

        vm.anno = anno;
        vm.seduta = entity;
        vm.workingBody = workingBody;
        vm.dateformat = "dd/MM/yyyy HH:mm";
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.clear = clear;
        
        initController();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('seduta', vm.workingBody , {reload: false});
        }

        function save () {
            vm.isSaving = true;
            if(SedutaCommonService.checkAnnoSedutaDataConvocazione(vm.anno, vm.seduta)) {
                if(SedutaCommonService.fromSedutaViewToSedutaBE(vm.seduta, vm.workingBody)) {
                    if (vm.seduta.id !== null) {
                        Seduta.update({typeOrg: vm.workingBody.tipoOrganismo, idOrg: vm.workingBody.id }, vm.seduta, onSaveSuccess, onSaveError);
                    } else {
                        Seduta.saveWithWB({typeOrg: vm.workingBody.tipoOrganismo, idOrg: vm.workingBody.id }, vm.seduta, onSaveSuccess, onSaveError);
                    }
                } else {
                    vm.isSaving = false;
                }
            } else {
                var message = "Stai lavorando alle sedute dell'anno " + vm.anno + ", ma la data di convocazione è relativa ad un anno diverso. " +
                "\nImpossibile salvare la seduta. Tornare nella lista sedute e selezionare l'anno coerente con la data di convocazione.";
                toastr.error(message, "Errore nel salvataggio della seduta.");
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('seduta', vm.workingBody, {reload: true});
        }

        function onSaveError(error) {
            toastr.error("Errore nel salvataggio della seduta.");
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataDiConvocazione = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function initController(){

            vm.isSaving = false;

            vm.seduta.legislatura = workingBody.legislatura;
            // Informazioni organizzatore
            vm.seduta.idOrganizzatore = workingBody.id;
            vm.seduta.tipoOrganizzatore = workingBody.tipoOrganismo;
            // nextNumeroSeduta.numero viene chiesto al backend ed è il prossimo numero di seduta per l'organismo che sta creando la seduta (può essere modificato dall'utente)
            if(nextNumeroSeduta) {
                vm.numeroSeduta = nextNumeroSeduta.numero;
            } else {
                vm.numeroSeduta = SedutaCommonService.getNumeroSedutaOfWorkingBody(vm.seduta, workingBody);
            }
            vm.seduta.numeroSeduta = vm.numeroSeduta;
            vm.organismiSeduta = organismiSeduta;

            SedutaCommonService.removeWorkingBodyFromOrganismiSeduta(organismiSeduta, workingBody);
            
            if(!vm.seduta.dataDiConvocazione) {
                vm.seduta.dataDiConvocazione = new Date();
            }
            vm.seduta.dataDiConvocazione.setSeconds(0);

            // In fase di edit seleziona solo i membri partecipanti
            if(!vm.seduta.membriSeduta) {
                vm.seduta.membriSeduta = [];
            } else {
                var adjMembri = [];
                for( var i in vm.seduta.membriSeduta) {
                    var membroCur = vm.seduta.membriSeduta[i];
                    if(!membroCur.isOrganizzatore){
                        for(var j in vm.organismiSeduta) {
                            var organismoCur = vm.organismiSeduta[j];
                            if(organismoCur.tipoOrganismo === membroCur.tipoMembro && organismoCur.id === membroCur.idMembro) {
                                adjMembri.push(organismoCur);
                            }
                        }
                       
                    }

                }
                vm.seduta.membriSeduta = adjMembri;
            }
            

            // Recupera il nome dell'organizzatore
            if(workingBody.nome != null) {
                vm.seduta.organizzatore = workingBody.nome;
            } else {
                if(workingBody.tipoOrganismo === 'C') {
                    Commissione.get({id: vm.workingBody.id}, {}, function(result) {
                        vm.seduta.organizzatore = result.nome;
                    });
                } else if(workingBody.tipoOrganismo === 'E') {
                    SoggettoEsterno.get({id: vm.workingBody.id}, {}, function(result) {
                        vm.seduta.organizzatore = result.nome;
                    });
                }
            }

        }

    }
})();
