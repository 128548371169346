(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ordine-del-giorno', {
            parent: 'seduta',
            url: '/{idSeduta}/ordine-del-giorno/{idODG}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gestioneAttiApp.ordineDelGiorno.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ordine-del-giorno/ordine-del-giorno-detail.html',
                    controller: 'OrdineDelGiornoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('ordineDelGiorno');
                    $translatePartialLoader.addPart('tipoODG');
                    $translatePartialLoader.addPart('puntoODG');
                    $translatePartialLoader.addPart('tipoPuntoODG');
                    $translatePartialLoader.addPart('espressioneRichiesta');
                    $translatePartialLoader.addPart('esitoPuntoODG');
                    $translatePartialLoader.addPart('seduta');
                    $translatePartialLoader.addPart('tipoSeduta');
                    $translatePartialLoader.addPart('statoSeduta');
                    $translatePartialLoader.addPart('tipoOrganizzatore');
                    $translatePartialLoader.addPart('statoODGType');
                    $translatePartialLoader.addPart('document-toolbar');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'OrdineDelGiorno', function($stateParams, OrdineDelGiorno) {
                    return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                }],
                seduta: ['$stateParams', 'Seduta', function($stateParams, Seduta) {
                    return Seduta.get({id : $stateParams.idSeduta}).$promise;
                }],
                editingMode: [function () {
                    return false;
                }],
                docTypes : [ 'TipoDocumento', function(TipoDocumento) {
                    return TipoDocumento.tipiDocumentoOdg({}).$promise;
                }],
                workingBody: ['$stateParams', function($stateParams) {
                    return {
                        id: $stateParams.idOrg,
                        tipoOrganismo: $stateParams.typeOrg,
                        nome: $stateParams.nameOrg
                    }
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'ordine-del-giorno',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('ordine-del-giorno.edit', {
            parent: 'ordine-del-giorno',
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ordine-del-giorno/ordine-del-giorno-detail.html',
                    controller: 'OrdineDelGiornoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('ordineDelGiorno');
                    $translatePartialLoader.addPart('tipoODG');
                    $translatePartialLoader.addPart('puntoODG');
                    $translatePartialLoader.addPart('tipoPuntoODG');
                    $translatePartialLoader.addPart('espressioneRichiesta');
                    $translatePartialLoader.addPart('esitoPuntoODG');
                    $translatePartialLoader.addPart('seduta');
                    $translatePartialLoader.addPart('tipoSeduta');
                    $translatePartialLoader.addPart('statoSeduta');
                    $translatePartialLoader.addPart('tipoOrganizzatore');
                    $translatePartialLoader.addPart('statoODGType');
                    $translatePartialLoader.addPart('document-toolbar');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'OrdineDelGiorno', function($stateParams, OrdineDelGiorno) {
                    return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                }],
                seduta: ['$stateParams', 'Seduta', function($stateParams, Seduta) {
                    return Seduta.get({id : $stateParams.idSeduta}).$promise;
                }],
                editingMode: [function () {
                    return true;
                }],
                docTypes : [ 'TipoDocumento', function(TipoDocumento) {
                    return TipoDocumento.tipiDocumentoOdg({}).$promise;
                }],
                workingBody: ['$stateParams', function($stateParams) {
                    return {
                        id: $stateParams.idOrg,
                        tipoOrganismo: $stateParams.typeOrg,
                        nome: $stateParams.nameOrg
                    }
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'ordine-del-giorno',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('ordine-del-giorno.new', {
            parent: 'seduta',
            url: '/{idSeduta}/ordine-del-giorno-new',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ordine-del-giorno/ordine-del-giorno-create.html',
                    controller: 'OrdineDelGiornoCreateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                seduta: ['$stateParams', 'Seduta', function($stateParams, Seduta) {
                    return Seduta.get({id : $stateParams.idSeduta}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('ordineDelGiorno');
                    $translatePartialLoader.addPart('tipoODG');
                    $translatePartialLoader.addPart('puntoODG');
                    $translatePartialLoader.addPart('tipoPuntoODG');
                    $translatePartialLoader.addPart('espressioneRichiesta');
                    $translatePartialLoader.addPart('esitoPuntoODG');
                    $translatePartialLoader.addPart('seduta');
                    $translatePartialLoader.addPart('tipoSeduta');
                    $translatePartialLoader.addPart('statoSeduta');
                    $translatePartialLoader.addPart('tipoOrganizzatore');
                    $translatePartialLoader.addPart('statoODGType');
                    return $translate.refresh();
                }],
                workingBody: ['$stateParams', function($stateParams) {
                    return {
                        id: $stateParams.idOrg,
                        tipoOrganismo: $stateParams.typeOrg,
                        nome: $stateParams.nameOrg
                    }
                }]
            }
        })
        .state('ordine-del-giorno.delete', {
            parent: 'ordine-del-giorno',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ordine-del-giorno/ordine-del-giorno-delete-dialog.html',
                    controller: 'OrdineDelGiornoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OrdineDelGiorno', function(OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.id}).$promise;
                        }],
                        workingBody: ['$stateParams', function($stateParams) {
                            return {
                                id: $stateParams.idOrg,
                                tipoOrganismo: $stateParams.typeOrg,
                                nome: $stateParams.nameOrg
                            }
                        }]
                    }
                }).result.then(function() {
                    $state.go('ordine-del-giorno', null, { reload: 'ordine-del-giorno' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
