(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account($resource) {
        var service = $resource('api/account/:testProtocolCredentials', {}, {
            'get': {method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response;
                    }
                }
            },
            'testProtocolCredentials': {
                method: 'GET',
                params: {testProtocolCredentials: 'testProtocolCredentials'},
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response.data;
                    }
                }
            }
        });

        return service;
    }
})();
