(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('OrdineDelGiornoDetailController', OrdineDelGiornoDetailController);

    OrdineDelGiornoDetailController.$inject = ['$scope', '$timeout', '$state', '$mdDialog', 'editingMode', 'previousState', 'toastr', 'entity', 'seduta', 'workingBody', 'docTypes', 'OrdineDelGiorno', 'Commissione', 'SoggettoEsterno', 'PuntoODG', 'Atto', 'SedutaCommonService','$mdSidenav', 'Principal'];
    function OrdineDelGiornoDetailController($scope, $timeout, $state, $mdDialog, editingMode, previousState, toastr, entity, seduta, workingBody, docTypes, OrdineDelGiorno, Commissione, SoggettoEsterno, PuntoODG, Atto, SedutaCommonService, $mdSidenav, Principal) {
        var vm = this;

        vm.previousState = previousState.name;
        vm.previousStateParams = previousState.params;
        vm.showStateHistory;
        vm.workingBody = workingBody;
        vm.seduta = seduta;
        vm.odg = entity;
        vm.dateformat = "dd/MM/yyyy HH:mm";
        vm.organizzatoreSeduta = {};
        vm.puntiODG = [];

        vm.odg.idSeduta = seduta.id;
        vm.editingMode = editingMode;
        vm.isOrganizzatore = SedutaCommonService.isOrganizzatore(vm.seduta, vm.workingBody);

        vm.save = save;
        vm.performButtonAction = performButtonAction;
        vm.toggleEsitoPublish = toggleEsitoPublish;
        vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.isDuplicateProgressive = isDuplicateProgressive;

        vm.docTypes = docTypes;

        var confirmTransitionDlg = $mdDialog.confirm()
            .title('Attenzione!')
            .textContent("L'operazione richiesta non è reversibile, sei sicuro di voler procedere?")
            .ariaLabel('conferma transizione di stato')
            .ok('Conferma')
            .cancel('Annulla');

        initButtons();
        findOrganizzatore(vm.seduta.membriSeduta);
        getPuntiODG();
        toTipoSeduta();


        function findOrganizzatore(membriSeduta) {
            membriSeduta.forEach(function (membro) {
                if(membro.isOrganizzatore) {
                    vm.organizzatoreSeduta.id = membro.idMembro;
                    vm.organizzatoreSeduta.numeroSeduta = membro.numeroSeduta;

                    if(membro.tipoMembro == "C") {
                        Commissione.get({id: membro.idMembro}, {}, function(result) {
                            vm.organizzatoreSeduta.nome = result.nome;
                        });           
                    }
                    else if (membro.tipoMembro == "E") {
                        SoggettoEsterno.get({id: membro.idMembro}, {}, function(result) {
                            vm.organizzatoreSeduta.nome = result.nome;
                        });
                    } 
                    vm.organizzatoreSeduta.tipo = membro.tipoMembro;
                }
            });
        }

        function toTipoSeduta() {
            if(vm.seduta.tipoSeduta == 'N') {
                vm.tipoSeduta = "Ordinaria"
            }
            else if(vm.seduta.tipoSeduta == 'C') {
                vm.tipoSeduta = "Congiunta"
            }
        }

        function getPuntiODG() {
            PuntoODG.findAllByIdODG(
                {idODG: vm.odg.id}, 
                function (data) {
                    if(data) {
                        vm.puntiODG = data;
                        getAttiCollegati();
                    }
                }, 
                function (error) {
                    toastr.error("Errore recuperando i Punto ODG :", error.data.message);
                }
            );
        }

        function initButtons(){
            vm.visibleButtons = [];

            if(SedutaCommonService.isOrganizzatore(vm.seduta, vm.workingBody)) {
                if(vm.odg.statoCorrenteODG.statoODG === 'A' && vm.seduta.statoCorrente.statoSeduta !== 'P') {
                    vm.visibleButtons.push(
                            {
                                title: "entity.action.publishODG",
                                action: publishODG,
                                iconClass: "glyphicon-send",
                                nextStateName : "P"
                            },
                            {
                                title: "entity.action.deleteODG",
                                action: deleteODG,
                                iconClass: "glyphicon-thumbs-down",
                                nextStateName : "X"
                            }
                    );
                } else if(vm.odg.statoCorrenteODG.statoODG === 'P' && vm.seduta.statoCorrente.statoSeduta !== 'P') {
                    vm.visibleButtons.push(
                            {
                                title: "entity.action.deleteODG",
                                action: deleteODG,
                                iconClass: "glyphicon-thumbs-down",
                                nextStateName : "X"
                            }
                    );
                }
            }
        }

        function getAttiCollegati() {
            if(vm.puntiODG) {
                vm.puntiODG.forEach(function (p) {
                    if(p.idAtto) {
                        Atto.get(
                            {id: p.idAtto}, 
                            function (data) {p.atto = data;}, 
                            function (error) {toastr.error("Errore nel recuper dell'Atto collegato: ", error.data.message);}
                        );
                    }
                });
            }
        }

        function save () {
            vm.isSaving = true;
            if (vm.odg.id !== null) {
                vm.odg = OrdineDelGiorno.update({id: vm.odg.id}, vm.odg, onSaveSuccess("Ordine del Giorno salvato con successo!"), onSaveError);
            } else {
                vm.odg = OrdineDelGiorno.save(vm.odg, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (message) {
            $scope.$emit('gestioneAttiApp:ordineDelGiornoUpdate', message);
            toastr.success(message);
            vm.isSaving = false;
            reloadPage();
        }

        function onSaveError () {
            toastr.error('Errore durante il salvataggio dell\'\Ordine del Giorno');
            vm.isSaving = false;
        }

        function onChangeSuccess() {
            toastr.success('Punto ODG spostato con successo!');
            vm.isSaving = false;
        }

        function onChangeError () {
            toastr.error('Errore durante la modifica del Punto ODG');
            vm.isSaving = false;
        }

        function publishODG() {
            $mdDialog.show(confirmTransitionDlg).then(function () {
                OrdineDelGiorno.publish({}, vm.odg, onPublishSuccess, onPublishError);
            });
        }

        function onPublishSuccess (data) {
            toastr.success("Ordine del Giorno pubblicato con successo!");
            vm.odg = data.ordineDelGiornoPublished;
            vm.isSaving = false;
            reloadPage();
        }

        function onPublishError (data) {
            console.log(data);
            toastr.error('Errore durante la pubblicazione dell\'Ordine del Giorno. Accertarsi che sia stato caricato il documento Ordine del Giorno.');
            vm.isSaving = false;
        }

        function deleteODG() {
            $state.go('ordine-del-giorno.delete', {id: vm.odg.id, workingBody: vm.workingBody}, {reload: true});
        }

        function performButtonAction(button){
            if(button.action){
                button.action( button.nextStateName);
            }
        }

        function reloadPage() {
            $timeout(function () {
                $state.reload();
            }, 0);
        }

        vm.changeOrder = function(direction, idx) {
            vm.elem1 = vm.puntiODG[idx];
            
            if((direction < 0 && idx > 0) || (direction >= 0 && idx >= 0 && idx < vm.puntiODG.length - 1)) {
                if(direction < 0 && idx > 0) {
                    vm.elem1.progressivoPuntoODG = idx
                    vm.elem2 = vm.puntiODG[idx - 1];                
                } else if(direction >= 0 && idx >= 0 && idx < vm.puntiODG.length - 1) {
                    vm.elem1.progressivoPuntoODG = idx + 2;
                    vm.elem2 = vm.puntiODG[idx + 1];
                }
                vm.elem2.progressivoPuntoODG = idx + 1;

                /*
                PuntoODG.update({idODG: vm.odg.id}, vm.elem2).$promise;
                PuntoODG.update({idODG: vm.odg.id}, vm.elem1, onChangeSuccess, onChangeError).$promise;*/
                
                PuntoODG.updateAll({idODG: vm.odg.id}, vm.puntiODG, onChangeSuccess, onChangeError).$promise;

                reloadPage();
            }
        }
            
        vm.canEdit = function() {
            return vm.editingMode && SedutaCommonService.isOrganizzatore(vm.seduta, vm.workingBody);
        }

        function toggleEsitoPublish () {
            $mdSidenav('esitoPublish').toggle();
        }

        function isDuplicateProgressive(index){
            var progToCheck = vm.puntiODG[index].progressivoPuntoODG;
            for(var i = 0; i < vm.puntiODG.length; i++){
                if (i != index){
                    if(progToCheck == vm.puntiODG[i].progressivoPuntoODG) return true;
                }
            }
            return false;
        }
    }
})();
