(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('MacroMateriaDeleteController', MacroMateriaDeleteController);

            MacroMateriaDeleteController.$inject = ['$uibModalInstance', 'entity', 'MacroMateria'];

    function MacroMateriaDeleteController($uibModalInstance, entity, MacroMateria) {
        var vm = this;

        vm.macroMateria = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            MacroMateria.delete({id: id},
                    function () {
                        $uibModalInstance.close(true);
                    });
        }
    }
})();
