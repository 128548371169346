(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('OrdineDelGiorno', OrdineDelGiorno);

    OrdineDelGiorno.$inject = ['$resource'];

    function OrdineDelGiorno ($resource) {
        var resourceUrl =  'api/ordine-del-giornos';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                url: resourceUrl + '/:id',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllByIdSeduta': {
                method: 'GET',
                url: resourceUrl + '/seduta' + '/:idSeduta',
                isArray: true
            },
            'update': { method:'PUT' },
            'publish': {
                method:'PUT',
                url: resourceUrl + "/publish",
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'delete': {
                method:'PUT',
                url: resourceUrl + "/delete/:id",
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
