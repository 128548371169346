(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('SoggettoEsterno', SoggettoEsterno);

    SoggettoEsterno.$inject = ['$resource'];

    function SoggettoEsterno ($resource) {
        var resourceUrl =  'api/:public/soggetto-esternos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', params: {public: 'public'}, isArray: true},
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
