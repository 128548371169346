(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('DocumentoContentController', DocumentoContentController);

    DocumentoContentController.$inject = ['Documento', 'toastr', 'entity', 'utente', 'FileSaver', '$window', '$sce'];

    function DocumentoContentController(Documento, toastr, entity, utente, FileSaver, $window, $sce) {
        var vm = this;

        vm.doc = entity;

        vm.utente = utente;
        vm.response = null;

        vm.mimeType = vm.doc.mimeType;

        vm.error = false;
        vm.errorStatus = null;
        getPath();


        function getPath() {
            Documento.getContent(vm.doc.id).then(function (response) {
                if (response && response.data) {
                    vm.response = response;
                    //L'endsWith non e' supportato su IE quindi va fatto prima il controllo che non sia IE!
                    if ( !isIE() && !isIphone() && !isAndroid() && (vm.mimeType === "application/pdf" || (vm.doc.nome.toLowerCase()).endsWith(".pdf") )) {
                        var file = new Blob([response.data], {
                            type: "application/pdf"
                        });

                        var fileURL = URL.createObjectURL(file);
                        vm.path = $sce.trustAsResourceUrl(fileURL);
                    } else {
                        vm.download();
                    }
                }
            }, function (error) {
                console.log('Impossibile visualizzare il file');
                toastr.error('Impossibile visualizzare il file', error.data.message);
                vm.errorStatus = error && error.status ? error.status : null;

                vm.error = true;
                vm.loading = false;
            });
        }

        function isIE() {
            return (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1));
        }

        function isIphone() {
            return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        }

        function isAndroid() {
            return !!navigator.platform && /android/i.test(navigator.platform);
        }

        vm.download = function() {
            var fileType = null;
            //L'endsWith non e' supportato su IE quindi va fatto prima il controllo che non sia IE!
            if(!isIE()){
                if(vm.mimeType === "application/pdf" || (vm.doc.nome.toLowerCase()).endsWith(".pdf") ){
                    fileType = 	'application/pdf';
                } else if((vm.doc.nome.toLowerCase()).endsWith(".doc")) {
                    fileType = 	'application/msword';
                } else if((vm.doc.nome.toLowerCase()).endsWith(".docx")){
                    fileType = 	'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                } else if((vm.doc.nome.toLowerCase()).endsWith(".xls")){
                    fileType = 	'application/vnd.ms-excel';
                } else if((vm.doc.nome.toLowerCase()).endsWith(".xlsx")){
                    fileType = 	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                } else if((vm.doc.nome.toLowerCase()).endsWith(".ppt")){
                    fileType = 	'application/vnd.ms-powerpoint';
                } else if((vm.doc.nome.toLowerCase()).endsWith(".pptx")){
                    fileType = 	'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                }  else if((vm.doc.nome.toLowerCase()).endsWith(".png")){
                    fileType = 	'image/png';
                }  else if((vm.doc.nome.toLowerCase()).endsWith(".jpeg")){
                    fileType = 	'image/jpeg';
                }  else if((vm.doc.nome.toLowerCase()).endsWith(".gif")){
                    fileType = 	'image/gif';
                }
            }
            if(fileType){
                FileSaver.saveAs(new Blob([vm.response.data], {type: fileType}), vm.doc.nome);
            } else {
                FileSaver.saveAs(new Blob([vm.response.data], {}), vm.doc.nome);
            }
        }

    }
})();
