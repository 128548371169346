(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('TipoDocumentoDialogController', TipoDocumentoDialogController);

    TipoDocumentoDialogController.$inject = ['$timeout', '$scope', '$translate', '$uibModalInstance', 'entity', 'TipoDocumento', 'GESTIONE_ATTI_DOC_VISIBILITIES', 'Commissione', 'CommissioneRuolo', 'SoggettoEsterno', 'Settore', 'RESOURCE_PERMISSIONS', 'ACT_TYPES'];

    function TipoDocumentoDialogController ($timeout, $scope, $translate, $uibModalInstance, entity, TipoDocumento, GESTIONE_ATTI_DOC_VISIBILITIES, Commissione, CommissioneRuolo, SoggettoEsterno, Settore, RESOURCE_PERMISSIONS, ACT_TYPES) {
        var vm = this;

        vm.tipoDocumento = entity;
        vm.authorities = RESOURCE_PERMISSIONS;

        vm.availableActTypes = ACT_TYPES;
        vm.availableCommissions = [];
        vm.availableCommissionsRoles = [];
        vm.availableExternalSubjects = [];
        vm.availableSectors = [];
        vm.visibilities = GESTIONE_ATTI_DOC_VISIBILITIES;
        
        angular.forEach(vm.visibilities, function(visibility) {
           visibility.text = $translate.instant(visibility.textKey); 
        });

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        // load availableSectors
        Settore.query({}, function (availableSectors) {
            vm.availableSectors = availableSectors;
        }, function (error) {
            toastr.error('Impossibile reperire la lista dei availableSectors: ' + error.message, 'Errore!');
        });

        // load availableCommissions
        Commissione.query({}, function (availableCommissions) {
            vm.availableCommissions = availableCommissions;
        }, function (error) {
            toastr.error('Impossibile reperire la lista delle availableCommissions: ' + error.message, 'Errore!');
        });

        // load availableCommissions ruoli
        CommissioneRuolo.query({}, function (availableCommissionsRoles) {
            vm.availableCommissionsRoles = availableCommissionsRoles;
        }, function (error) {
            toastr.error('Impossibile reperire la lista dei ruoli delle availableCommissions: ' + error.message, 'Errore!');
        });

        // load soggetti
        SoggettoEsterno.query({}, function (soggetto) {
            vm.availableExternalSubjects = soggetto;
        }, function (error) {
            toastr.error('Impossibile reperire la lista dei soggetti esterni: ' + error.message, 'Errore!');
        });

        vm.toggleAuthorityPermission = function (authority) {
            var found = -1;
            for (var i=0; i<vm.tipoDocumento.authorities.length; i++) {
                var auth = vm.tipoDocumento.authorities[i];
                if (authority.name === auth.name) {
                    found = i;
                    break;
                }
            }
            if (found === -1) {
                vm.tipoDocumento.authorities.push(authority);
            } else {
                vm.tipoDocumento.authorities.splice(found, 1);
                switch (authority.name) {
                    case "ROLE_SETTORE": vm.tipoDocumento.settori = [];
                        break;
                    case "ROLE_EXTERNAL": vm.tipoDocumento.soggettiEsterni = [];
                        break;
                    case "ROLE_COMMISSIONE": vm.tipoDocumento.commissioni = [];
                        break;
                }
            }
        };

        vm.toggleAuthoritySpecPermission = function(authority, authoritySpec) {
            var found = -1;
            for (var i=0; i<vm.tipoDocumento[authority].length; i++) {
                var auth = vm.tipoDocumento[authority][i];
                if (authoritySpec.id === auth.id) {
                    found = i;
                    break;
                }
            }
            (found > -1) ? vm.tipoDocumento[authority].splice(found, 1) : vm.tipoDocumento[authority].push(authoritySpec);
        };

        vm.toggleActTypePermission = function (actType) {
            var found = -1;
            for (var i=0; i<vm.tipoDocumento.tipiAtto.length; i++) {
                var type = vm.tipoDocumento.tipiAtto[i];
                if (type === actType) {
                    found = i;
                    break;
                }
            }
            if (found === -1) {
                vm.tipoDocumento.tipiAtto.push(actType);
            } else {
                vm.tipoDocumento.tipiAtto.splice(found, 1);
            }
        };

        vm.hasPermissionOnAuthority = function (authority) {
            for (var i = 0; i < vm.tipoDocumento.authorities.length; i++) {
                var auth = vm.tipoDocumento.authorities[i];
                if (auth.name === authority) {
                    return true;
                }
            }
            return false;
        };

        vm.hasPermissionOnAuthoritySpec = function (authority, authoritySpec) {
            for (var i = 0; i < vm.tipoDocumento[authority].length; i++) {
                var authSpec = vm.tipoDocumento[authority][i];
                if (authSpec.id === authoritySpec.id) {
                    return true;
                }
            }
            return false;
        };

        vm.hasPermissionOnActType = function (actType) {
            for (var i = 0; i < vm.tipoDocumento.tipiAtto.length; i++) {
                var type = vm.tipoDocumento.tipiAtto[i];
                if (actType === type) {
                    return true;
                }
            }
            return false;
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.tipoDocumento.id !== null) {
                TipoDocumento.update(vm.tipoDocumento, onSaveSuccess, onSaveError);
            } else {
                TipoDocumento.save(vm.tipoDocumento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gestioneAttiApp:tipoDocumentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
