(function () {
    'use strict';
    angular
            .module('gestioneAttiApp')
            .factory('Direzione', Direzione);

    Direzione.$inject = ['$resource'];

    function Direzione($resource) {
        var resourceUrl = 'api/:public/direziones/:id/:protocolloAnagrafic';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params: {public: 'public'},
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'listFromProtocol': {method: 'GET', params: {protocolloAnagrafic: 'protocolloAnagrafic'}, isArray: true}
        });
    }
})();
