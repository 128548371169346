(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('GestioneNotificheController', GestioneNotificheController);

        GestioneNotificheController.$inject = ['$state','toastr', 'ListaDiDistribuzione', 'ACT_TYPES', 'EVENTI_NOTIFICA_ATTO', 'Atto'];

    function GestioneNotificheController($state, toastr, ListaDiDistribuzione, ACT_TYPES, EVENTI_NOTIFICA_ATTO, Atto) {
        var vm = this;
        vm.loadingListe = false;
        vm.loadingConfiguration = false;
        vm.listeDiDistribuzione = [];
        vm.actTypes = ACT_TYPES;
        vm.listaEventi = EVENTI_NOTIFICA_ATTO;
        vm.selectedAtto = null;
        vm.selectedAttoConfig = null;
        vm.isSavingConfig = false;
        vm.autocompleteModelMap = {};
        vm.autocompleteSearchtextMap = {};

        loadAllListeDiDistribuzione();

        function loadAllListeDiDistribuzione() {
            vm.loadingListe = true;
            ListaDiDistribuzione.query(function (result) {
                vm.listeDiDistribuzione = result;
                vm.loadingListe = false;
            },
            function (error) {
                toastr.error('Si e\' verificato un errore durante il caricamento delle liste di distribuzione disponibili!' , error)
                vm.loadingListe = false;
            });
        }

        vm.loadConfigurazioneNotifiche = function() {
            vm.loadingConfiguration = true;
            if(vm.selectedAtto) {
                Atto.getConfigurazioneNotificheByTipoAtto({tipoAtto: vm.selectedAtto}, 
                    function (result) {
                        initConfig(result);
                        vm.loadingConfiguration = false;
                    },
                    function (error){
                        console.log('Errore durante il caricamento della configurazione del tipo atto ' + vm.selectedAtto, error);
                        vm.loadingConfiguration = false;
                    });
            }
        }

        function initConfig(attoConfig) {
            if(!attoConfig || !attoConfig.tipoAtto) {
                attoConfig = {tipoAtto: vm.selectedAtto, mappaNotifiche: {}};
            }  
            if(!attoConfig.mappaNotifiche) {
                attoConfig.mappaNotifiche = {};
            }
            initEventMap(attoConfig.mappaNotifiche);
            vm.listaEventi.forEach(function(evento) {
                if(attoConfig.mappaNotifiche[evento.value].length > 0) {
                    replaceIdsWithListe(attoConfig.mappaNotifiche[evento.value]);
                }
            });
            vm.selectedAttoConfig = attoConfig;
        }

        function initEventMap(map) {
            vm.listaEventi.forEach(function(eventObject) {
                var event = eventObject.value;
                if(!map[event]) {
                    map[event] = [];
                }
            });
        }

        function replaceIdsWithListe(listaIds) {
           for (var i = listaIds.length - 1; i >= 0; i--) {
               var indexLista = vm.listeDiDistribuzione.findIndex(function (lista) {return listaIds[i] === lista.id});
               if(indexLista >= 0) {
                    listaIds[i] = vm.listeDiDistribuzione[indexLista];  
               } else {
                   // Se non e' stato trovato probabilmente quella lista e' stata eliminata dal DB
                   listaIds.splice(i, 1);
               } 
            }
        }


        function getMapWithListeIds(mapWithListe) {
            var mapWithListeIds = {};
            vm.listaEventi.forEach(function(evento) {
                mapWithListeIds[evento.value] = getArrayListeIds(mapWithListe[evento.value]); 
            });
            return mapWithListeIds;
        }

        function getArrayListeIds(listeArray) {
            var ids = [];
            listeArray.forEach(function(lista){
                ids.push(lista.id);
            });
            return ids;
        }


        vm.searchLista = function (searchText) {
            return searchText ? vm.listeDiDistribuzione.filter(createFilterForLista(searchText)) : [];
        };

        function createFilterForLista(query) {
            var lowercaseQuery = angular.lowercase(query);

            return function filterFn(lista) {
                var nomeLista = lista.nomeLista ? lista.nomeLista.toLowerCase() : '';
                var mail = lista.mail ? lista.mail.toLowerCase() : ''
                return nomeLista.indexOf(lowercaseQuery) >= 0 || mail.indexOf(lowercaseQuery) >= 0;
            };
        }

        vm.saveConfig = function() {
            vm.isSavingConfig = true;
            var configToSave = {
                id: vm.selectedAttoConfig.id,
                tipoAtto: vm.selectedAttoConfig.tipoAtto,
                mappaNotifiche: getMapWithListeIds(vm.selectedAttoConfig.mappaNotifiche)
            }
            if(vm.selectedAttoConfig.id) {
                Atto.updateConfigurazioneNotifiche({idConfigurazione: configToSave.id}, configToSave, onConfigSaveSuccess, onConfigSaveError);
            } else {
                Atto.createConfigurazioneNotifiche(configToSave, onConfigSaveSuccess, onConfigSaveError);
            }
        }

        function onConfigSaveSuccess() {
            vm.isSavingConfig = false;
            toastr.success('Salvataggio configurazione notifiche per il tipo atto ' + vm.selectedAtto + ' avvenuto con successo!');
        }

        function onConfigSaveError() {
            vm.isSavingConfig = false;
            toastr.error('Errore durante il salvataggio della configurazione notifiche per il tipo atto ' + vm.selectedAtto);
        }
    }
})();