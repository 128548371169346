(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('DocumentoTreeController', DocumentoTreeController);

    DocumentoTreeController.$inject = ['Documento', 'Atto', 'toastr', 'entity', 'utente', 'FileSaver', '$window', '$stateParams', 'ArchiveTreeService'];

    function DocumentoTreeController(Documento, Atto, toastr, entity, utente, FileSaver, $window, $stateParams, ArchiveTreeService) {
        var vm = this;


        vm.atto = entity;

        vm.statoSezione = $stateParams.stato;
        vm.nomeSezione = $stateParams.sezione;

        vm.utente = utente;

        getArchiveTreeSection();

        vm.downloadFile = downloadFile;
        vm.getLink = ArchiveTreeService.getLink;


        function getArchiveTreeSection() {
            if (vm.statoSezione) {
                Atto.getArchiveTreeSection(vm.atto.id, vm.statoSezione, vm.nomeSezione, null).then(function (response) {
                    vm.archiveTree = [response.data];
                });
            } else {
                Atto.getArchiveTree(vm.atto.id, null).then(function (response) {
                    vm.archiveTree = [response.data];
                });
            }
        }

        function downloadFile(id, name) {

            vm.loading = true;
            Documento.get({id: id}, {}, function (doc) {
                Documento.getContent(id).then(function (response) {
                    if (response && response.data) {
                        var mimeType = doc.mimeType;

                        if (mimeType !== "") {
                            var file = new Blob([response.data], {
                                //type: mimeType
                            });
                            var fileURL = URL.createObjectURL(file);

                            $window.open(fileURL);

                        } else {
                            FileSaver.saveAs(new Blob([response.data], {}), name);
                        }
                    }

                    vm.loading = false;
                }, function (error) {
                    console.log('Impossibile scaricare il fascicolo');
                    toastr.error('Impossibile scaricare il fascicolo', error.data.message);
                    vm.loading = false;
                });
            });
        }
        
    }
})();
