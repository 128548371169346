(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('CommissioneRuoloController', CommissioneRuoloController);

    CommissioneRuoloController.$inject = ['CommissioneRuolo'];

    function CommissioneRuoloController(CommissioneRuolo) {

        var vm = this;

        vm.commissioneRuolos = [];

        loadAll();

        function loadAll() {
            CommissioneRuolo.query(function(result) {
                vm.commissioneRuolos = result;
                vm.searchQuery = null;
            });
        }
    }
})();
