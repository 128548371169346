(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('CommissioneRuoloDetailController', CommissioneRuoloDetailController);

    CommissioneRuoloDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CommissioneRuolo'];

    function CommissioneRuoloDetailController($scope, $rootScope, $stateParams, previousState, entity, CommissioneRuolo) {
        var vm = this;

        vm.commissioneRuolo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gestioneAttiApp:commissioneRuoloUpdate', function(event, result) {
            vm.commissioneRuolo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
