(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('macro-materia', {
                    parent: 'app',
                    url: '/macro-materia?page&code&description',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'macro-materia.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/macro-materia/macro-materia.html',
                            controller: 'MacroMateriaController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'descrizioneAnagrafica,desc',
                            squash: true
                        },
                        code: {
                            value: null,
                            squash: true
                        },
                        description: {
                            value: null,
                            squash: true
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');
                                $translatePartialLoader.addPart('macro-materia');
                                return $translate.refresh();
                            }],
                        pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                /* predicate: PaginationUtil.parsePredicate($stateParams.sort), */
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                code: $stateParams.code,
                                description: $stateParams.description
                            }
                        }]
                    }
                }).state('macro-materia.edit', {
                    parent: 'macro-materia',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$mdDialog', 'MacroMateria', '$state', function ($stateParams, $mdDialog, MacroMateria, $state) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: false,
                                templateUrl: 'app/entities/macro-materia/macro-materia-dialog.html',
                                controller: 'MacroMateriaDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: MacroMateria.get({id: $stateParams.id}).$promise
                                }
                            }).then(function (result) {
                                $state.go('macro-materia', null, {reload: true});
                              },
                              function () {
                                $state.go('macro-materia', null, {reload: false});
                              });
                        }]
                }).state('macro-materia.new', {
                    parent: 'macro-materia',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$mdDialog', '$state', function ($mdDialog, $state) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: false,
                                templateUrl: 'app/entities/macro-materia/macro-materia-dialog.html',
                                controller: 'MacroMateriaDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: {}
                                }
                            }).then(function (result) {
                                $state.go('macro-materia', null, {reload: true});
                              },
                              function () {
                                $state.go('macro-materia', null, {reload: false});
                              });
                        }]
                }).state('macro-materia.delete', {
                    parent: 'macro-materia',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/macro-materia/macro-materia-delete-dialog.html',
                                controller: 'MacroMateriaDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['MacroMateria', function (MacroMateria) {
                                            return MacroMateria.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('macro-materia', null, {reload: 'macro-materia'});
                            },
                            function () {
                              $state.go('macro-materia', null, {reload: false});
                            });
                        }]
                })
            }
})();