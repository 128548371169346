(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('SincronizzatoreAnagrafiche', SincronizzatoreAnagrafiche);

        SincronizzatoreAnagrafiche.$inject = ['$resource'];

    function SincronizzatoreAnagrafiche ($resource) {
        var resourceUrl =  'api/:public/sincronizzatore-anagrafiche/:id/:latest';

        return $resource(resourceUrl, {}, {
            'startSyncro': { method: 'POST', isArray: false},
            'getRunningSyncro': {
                method: 'GET',
                isArray: true
/*                 transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                } */
            },
            'getLatestSyncro': {
                method: 'GET',
                isArray: true,
                params: {latest: 'latest'}
            }
        });
    }
})();