(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('MateriaController', MateriaController);

        MateriaController.$inject = ['$state','toastr', 'pagingParams', 'paginationConstants', 'ParseLinks', 'Materia', 'macroMateriaList'];

    function MateriaController($state, toastr, pagingParams, paginationConstants, ParseLinks, Materia, macroMateriaList) {
        var vm = this;
        vm.materiaList = [];
        vm.macroMateriaList = macroMateriaList;
        //Search default
        vm.pagingParams = pagingParams || {};

         // query stuff
         vm.predicate = vm.pagingParams.predicate;
         vm.reverse = vm.pagingParams.ascending;
         vm.code = vm.pagingParams.code;
         vm.description = vm.pagingParams.description;
         vm.itemsPerPage = paginationConstants.itemsPerPage;
         vm.macroMateriaId = vm.pagingParams.macroMateriaId;
         vm.macroMateriaSelected;

         if (vm.macroMateriaId) {
            vm.macroMateriaSelected = vm.macroMateriaList.find(function(mm){ return mm.id === vm.macroMateriaId}); 
         }

         vm.loading = false;

         loadAll();

         function loadAll() {
             vm.loading = true;
             Materia.queryPaged({
                page: vm.pagingParams.page - 1,
                sort: sort(),
                size: vm.itemsPerPage,
                code: vm.code,
                description: vm.description,
                macroMateriaId: vm.macroMateriaSelected ? vm.macroMateriaSelected.id : null
            }, onSuccess, onError);
         }

         function onSuccess(data, headers) {
             vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.materiaList = data;
             vm.page = vm.pagingParams.page;
             vm.loading = false;
         }

         function onError(error) {
             console.log("Failure!", error);
             toastr.error(error.data.message);
             vm.loading = false;
         }

         function sort() {
             var result = [ ('macro_materia.code' + ',asc'),  ('code' + ',asc')];
             return result;
         }

        vm.transition = function() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                code: vm.code,
                description: vm.description,
                macroMateriaId: vm.macroMateriaSelected ? vm.macroMateriaSelected.id : null
            });
        }
    }
})();