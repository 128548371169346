(function () {
    'use strict';
    angular
            .module('gestioneAttiApp')
            .factory('Persona', Persona);

    Persona.$inject = ['$resource'];

    function Persona($resource) {
        var resourceUrl = 'api/:public/personas/:id/:roleEndpoint/:ruolo/:protocolloAnagrafic';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                params: {public: 'public'}
            },
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'listByRole': {method: 'GET', params: {public: 'public', roleEndpoint: 'ruolo'}, isArray: true},
            'listFromProtocol': {method: 'GET', params: {protocolloAnagrafic: 'protocolloAnagrafic'}, isArray: true}
        });
    }
})();
