(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('PersonaDialogController', PersonaDialogController);

    PersonaDialogController.$inject = ['$timeout', '$scope', 'toastr', '$uibModalInstance', 'entity', 'Persona', '$q'];

    function PersonaDialogController($timeout, $scope, toastr, $uibModalInstance, entity, Persona, $q) {
        var vm = this;

        vm.persona = entity;
        vm.clear = clear;
        vm.save = save;
        
        vm.selectedItem = vm.persona.anagraficaProtocollo;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.persona.id !== null) {
                Persona.update(vm.persona, onSaveSuccess, onSaveError);
            } else {
                Persona.save(vm.persona, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gestioneAttiApp:personaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.setAnagrafic = function (item) {
            if (item) {
                vm.persona.anagraficaProtocollo = item;
            }
        };

        vm.searchAnagrafics = function (text) {
            var deferred = $q.defer();
            Persona.listFromProtocol({'code': text}, function (data) {
                deferred.resolve(data);
                return deferred.promise;
            }, function (error) {
                toastr.error(error);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        vm.getDisplayName = function (item) {
            return item.anagraficCode + ' - ' + item.anagraficDescription;
        };
    }
})();
