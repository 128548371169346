(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('lista-di-distribuzione', {
                    parent: 'admin',
                    url: '/lista-di-distribuzione?page',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'lista-di-distribuzione.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/lista-di-distribuzione/lista-di-distribuzione.html',
                            controller: 'ListaDiDistribuzioneController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'nomeLista,desc',
                            squash: true
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');
                                $translatePartialLoader.addPart('lista-di-distribuzione');
                                return $translate.refresh();
                            }],
                        pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort)
                            }
                        }]
                    }
                }).state('lista-di-distribuzione.new', {
                    parent: 'lista-di-distribuzione',
                    url: '/new',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$mdDialog', function ($mdDialog) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: true,
                                templateUrl: 'app/entities/lista-di-distribuzione/lista-di-distribuzione-dialog.html',
                                controller: 'ListaDiDistribuzioneDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: {
                                        nomeLista: null,
                                        mail: null,
                                        descrizione: null
                                    }
                                }
                            });
                        }]
                }).state('lista-di-distribuzione.edit', {
                    parent: 'lista-di-distribuzione',
                    url: '/edit/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['ListaDiDistribuzione', '$stateParams', '$mdDialog', function (ListaDiDistribuzione, $stateParams, $mdDialog) {
                            $mdDialog.show({
                                clickOutsideToClose: false,
                                fullscreen: true,
                                templateUrl: 'app/entities/lista-di-distribuzione/lista-di-distribuzione-dialog.html',
                                controller: 'ListaDiDistribuzioneDialogController',
                                controllerAs: 'vm',
                                bindToController: true,
                                locals: {
                                    entity: ListaDiDistribuzione.get({id: $stateParams.id}).$promise
                                }
                            });
                        }]
                }).state('lista-di-distribuzione.delete', {
                    parent: 'lista-di-distribuzione',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                            $uibModal.open({
                                templateUrl: 'app/entities/lista-di-distribuzione/lista-di-distribuzione-delete-dialog.html',
                                controller: 'ListaDiDistribuzioneDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['ListaDiDistribuzione', function (ListaDiDistribuzione) {
                                            return ListaDiDistribuzione.get({id: $stateParams.id}).$promise;
                                        }]
                                }
                            }).result.then(function () {
                                $state.go('lista-di-distribuzione', null, {reload: 'lista-di-distribuzione'});
                            }, function () {
                                $state.go('^');
                            });
                        }]
                }).state('gestione-notifiche', {
                    parent: 'admin',
                    url: '/notification-management',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'gestione-notifiche.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/lista-di-distribuzione/gestione-notifiche.html',
                            controller: 'GestioneNotificheController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'nomeLista,desc',
                            squash: true
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('lista-di-distribuzione');
                                return $translate.refresh();
                            }]
                    }
                })
            }
})();