(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('DirezioneDetailController', DirezioneDetailController);

    DirezioneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Direzione'];

    function DirezioneDetailController($scope, $rootScope, $stateParams, previousState, entity, Direzione) {
        var vm = this;

        vm.direzione = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gestioneAttiApp:direzioneUpdate', function(event, result) {
            vm.direzione = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
