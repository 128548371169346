(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SedutaCopyController',SedutaCopyController);

        SedutaCopyController.$inject = ['$uibModalInstance', '$stateParams', 'puntiODG','toastr'];

    function SedutaCopyController($uibModalInstance, $stateParams, puntiODG, toastr) {
        var vm = this;

        vm.numeroSeduta = $stateParams.numeroSeduta;
        vm.legislatura = $stateParams.legislatura;
        vm.puntiODG = puntiODG;
        vm.title = "";
        vm.textToCopy = [];

        vm.clear = clear;
        vm.copyRichTextToClipboard = copyRichTextToClipboard;
        vm.generateRichTextToCopy = generateRichTextToCopy;

        generateTextToCopy();
        generateRichTextToCopy();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function generateTextToCopy() {
            vm.title = "Legislatura " + vm.legislatura 
                + " - Seduta n°" + vm.numeroSeduta;
            
            
            for (var i = 0; i < vm.puntiODG.length; i++) {
                vm.textToCopy[i] = {};
                vm.textToCopy[i].text = vm.puntiODG[i].testoPuntoODG;
                vm.textToCopy[i].esito = vm.puntiODG[i].descrizioneEsitoPuntoODG;
                //if(vm.puntiODG[i].idAtto) {
                    //vm.textToCopy[i].attachedActUrl = "https://iterlegis.consiglio.regione.toscana.it/#/atto/" + vm.puntiODG[i].idAtto + "/";
                //}

            }
        }

        function generateRichTextToCopy() {
            /*vm.richTextToCopy = 
                "<p style=\"text-align: center;\"><span style=\"font-size: large;\"><strong>" + vm.title + "</strong></span></p> \n";

            vm.richTextToCopy = vm.richTextToCopy + "<table> \n";*/
            vm.richTextToCopy = "<table> \n";

            for (var i = 0; i < vm.puntiODG.length; i++) {
                if(vm.puntiODG[i].descrizioneEsitoPuntoODG) {
                    vm.richTextToCopy = vm.richTextToCopy + "<tr> \n";
                
                    if(vm.textToCopy[i].attachedActUrl) {
                        vm.richTextToCopy = vm.richTextToCopy + "<td><a href=\"" + vm.textToCopy[i].attachedActUrl + "\">" + vm.puntiODG[i].testoPuntoODG + "</a></td> \n";
                    } else {
                        vm.richTextToCopy = vm.richTextToCopy + "<td>" + vm.puntiODG[i].testoPuntoODG + "</td> \n";
                    }
                    vm.richTextToCopy = vm.richTextToCopy + "<td>" + vm.puntiODG[i].descrizioneEsitoPuntoODG + "</td> \n";
                
                vm.richTextToCopy = vm.richTextToCopy + "</tr> \n";
                }
            }      
            vm.richTextToCopy = vm.richTextToCopy + "</table> \n";
        }

        function copyRichTextToClipboard(textToCopy) {
            function listener(e) {
              e.clipboardData.setData("text/html", textToCopy);
              e.clipboardData.setData("text/plain", textToCopy);
              e.preventDefault();
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);

            toastr.success("Affari trattati copiati nella clipboard!");

        }     

    }
})();