(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('StatoODGController', StatoODGController);

    StatoODGController.$inject = ['StatoODG'];

    function StatoODGController(StatoODG) {

        var vm = this;

        vm.statoODGS = [];

        loadAll();

        function loadAll() {
            StatoODG.query(function(result) {
                vm.statoODGS = result;
                vm.searchQuery = null;
            });
        }
    }
})();
