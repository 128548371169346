(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('DirezioneDialogController', DirezioneDialogController);

    DirezioneDialogController.$inject = ['$timeout', '$scope', 'toastr', '$uibModalInstance', 'entity', 'Direzione', '$q'];

    function DirezioneDialogController($timeout, $scope, toastr, $uibModalInstance, entity, Direzione, $q) {
        var vm = this;

        vm.direzione = entity;
        vm.clear = clear;
        vm.save = save;

        vm.selectedItem = vm.direzione.anagraficaProtocollo;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.direzione.id !== null) {
                Direzione.update(vm.direzione, onSaveSuccess, onSaveError);
            } else {
                Direzione.save(vm.direzione, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gestioneAttiApp:direzioneUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.setAnagrafic = function (item) {
            if (item) {
                vm.direzione.anagraficaProtocollo = item;
            }
        };

        vm.searchAnagrafics = function (text) {
            var deferred = $q.defer();
            Direzione.listFromProtocol({'code': text}, function (data) {
                deferred.resolve(data);
                return deferred.promise;
            }, function (error) {
                toastr.error(error);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        vm.getDisplayName = function (item) {
            return item.anagraficCode + ' - ' + item.anagraficDescription;
        };
    }
})();
