(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('MacroMateriaController', MacroMateriaController);

        MacroMateriaController.$inject = ['$state','toastr', 'pagingParams', 'paginationConstants', 'ParseLinks', 'MacroMateria'];

    function MacroMateriaController($state, toastr, pagingParams, paginationConstants, ParseLinks, MacroMateria) {
        var vm = this;
        vm.macroMateriaList = [];
        //Search default
        vm.pagingParams = pagingParams || {};

         // query stuff
         vm.predicate = vm.pagingParams.predicate;
         vm.reverse = vm.pagingParams.ascending;
         vm.code = vm.pagingParams.code;
         vm.description = vm.pagingParams.description;
         vm.itemsPerPage = paginationConstants.itemsPerPage;

         vm.loading = false;


         loadAll();

         function loadAll() {
             vm.loading = true;
             MacroMateria.queryPaged({
                page: vm.pagingParams.page - 1,
                sort: sort(),
                size: vm.itemsPerPage,
                code: vm.code,
                description: vm.description
            }, onSuccess, onError);
         }

         function onSuccess(data, headers) {
             vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.macroMateriaList = data;
             vm.page = vm.pagingParams.page;
             vm.loading = false;
         }

         function onError(error) {
             console.log("Failure!", error);
             toastr.error(error.data.message);
             vm.loading = false;
         }

         function sort() {
            var result = [('code' + ',asc')];
            return result;
         }

        vm.transition = function() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                code: vm.code,
                description: vm.description
            });
        }
    }
})();