(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('MacroMateriaDialogController', MacroMateriaDialogController);

            MacroMateriaDialogController.$inject = ['$timeout', '$scope', 'toastr', '$mdDialog', 'entity', 'MacroMateria', '$state'];

    function MacroMateriaDialogController($timeout, $scope, toastr, $mdDialog, entity, MacroMateria, $state) {
        var vm = this;
        vm.macroMateria = vm.entity;
        vm.clear = clear;
        vm.save = save;
        vm.endMinDate = null;

        if (vm.entity && vm.entity.id) {
            updateEndMinDate();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.isFormValid = function() {
            return vm.macroMateria.code && vm.macroMateria.description && vm.macroMateria.validityStartDate;
        }
        vm.startDateChanged = function() {
            if (vm.macroMateria.validityStartDate && vm.macroMateria.validityEndDate && vm.macroMateria.validityStartDate >= vm.macroMateria.validityEndDate ) {
                vm.macroMateria.validityEndDate = null;
            }
            updateEndMinDate(); 
        }
        function clear() {
            $mdDialog.cancel();
        }

        function save() {
            vm.isSaving = true;
           if (vm.macroMateria.id) {
                MacroMateria.update(vm.macroMateria, onSaveSuccess, onSaveError);
            } else {
                MacroMateria.save(vm.macroMateria, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gestioneAttiApp:macroMateriaUpdate', result);
            vm.isSaving = false;
            $mdDialog.cancel();
            $state.go('macro-materia', null, {reload: true});
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        function updateEndMinDate() {
            vm.endMinDate = new Date(vm.macroMateria.validityStartDate);
            vm.endMinDate.setDate(vm.endMinDate.getDate() + 1);
        }
    }
})();
