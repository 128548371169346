(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'Settore', 'User', '$mdMedia', 'toastr', '$anchorScroll', '$location', '$window'];

    function NavbarController($rootScope, $state, Auth, Principal, ProfileService, LoginService, Settore, User, $mdMedia, toastr, $anchorScroll, $location, $window) {
        var vm = this;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.screenIsSmall = $mdMedia('xs') || $mdMedia('sm');

        vm.isCommissioneAtti = $location.$$url.includes('commissione-atti');

        vm.canViewProtoSync = Principal.hasAnyAuthority(['ROLE_ADMIN']) || Principal.hasAnyAuthority(['ROLE_PROTOCOLLO']);
        vm.canViewImporterMonitorBoard = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_PROTOCOLLO']);
        vm.isAdmin = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.showReportGenerator = false;
        vm.showAdempimentiReportGenerator = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_COMMISSIONE', 'ROLE_AULA']);
        vm.showGestioneSedute = vm.isAuthenticated && Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_COMMISSIONE', 'ROLE_EXTERNAL']);

        ProfileService.getProfileInfo().then(function (response) {
            vm.inDev = response.inDev;
            vm.inStaging = response.inStaging;
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
            vm.windowsAuthEnabled = response.windowsAuthEnabled;
        });

        if (vm.isAuthenticated()) {
            Principal.identity().then(function (user) {
                vm.user = user;
                if (user) {
                    vm.showReportGenerator = vm.user.reportGeneratorEnabled;
                    $rootScope.userDescription = user.firstName + " " + user.lastName;
                    if (!Principal.isEnteUser(user.authorities) && (!user.protocolLogin || !user.protocolPassword) && user.instantProtocolEnabled) {
                        toastr.warning("Non hai impostato le tue credenziali di protocollo! Fallo subito cliccando in alto a destra sul tuo nome e quindi su Impostazioni.")
                    }
                }
            });
        }

        // RIMUOVERE UNA VOLTA TOLTA FUNZIONALITA' DI INSERT
        $rootScope.$watch('userDescription', function () {
            if ($rootScope.userDescription) {
                initAzioniSettore();
            }
            if (vm.isAuthenticated()) {
                Principal.identity().then(function (user) {
                    vm.user = user;
                });
            }
        });

        vm.canInsert = function () {
            return vm.inDev
                    && (Principal.hasAnyAuthority(['ROLE_ADMIN'])
                            || Principal.hasAnyAuthority(['ROLE_PROTOCOLLO'])
                            || (vm.azioniSettoriById && User.isSectorUserEnabled(vm.user, ['GENERAL_SECTIONS'], vm.azioniSettoriById)));
        };

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        vm.isTemporaryUser = function() {
            return vm.user && Principal.hasAnyAuthority(['ROLE_TEMPORARYENTE']);
        };

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            vm.showGestioneSedute = false;
            if (!vm.windowsAuthEnabled) {
                collapseNavbar();
                Auth.logout();
                vm.user = null;
                $state.go('home');
            }
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        // RIMUOVERE UNA VOLTA TOLTA FUNZIONALITA' DI INSERT
        function initAzioniSettore() {
            if (Principal.hasAnyAuthority(['ROLE_SETTORE'])) {
                Settore.query({}, function (settori) {
                    vm.azioniSettoriById = {};
                    angular.forEach(settori, function (settore) {
                        vm.azioniSettoriById[settore.id] = settore.azioniAbilitate;
                    });
                });
            }
        }

        vm.onClickNotificheAnchor = function () {
            $anchorScroll('notifiche-anchor-name');
        }

        $rootScope.$on('$stateChangeSuccess', function (event) {
            $window.gtag('config', 'G-Y8RPM76EDY', {
                'cookie_domain': 'consiglio.regione.toscana.it',
                'page_path': $location.path()});
            $window.gtag('event', 'page_view');

        });
    }
})();
