(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .factory('User', User);

    User.$inject = ['$resource', 'Principal'];

    function User($resource, Principal) {
        var service = $resource('api/users/:login/:fromLdap', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'fromLdap': {method: 'GET', params: {fromLdap: 'fromLdap'}, isArray: true},
        });

        /**
         * Restituisce true se l'utente appartiene ad una o più delle 
         * commissioni fornite, false altrimenti.
         * 
         * @param {type} utente
         * @param {type} commissions
         * @returns {Boolean}
         */
        service.hasAnyCommission = function (utente, commissions) {
            if (!utente || !utente.commissioni || !commissions) {
                return false;
            }
            for (var i = 0; i < commissions.length; i++) {
                var id = null;
                if (angular.isObject(commissions[i])) {
                    id = commissions[i].id;
                } else {
                    id = commissions[i];
                }
                if (utente.commissioni.indexOf(id) > -1) {
                    return true;
                }
            }
            return false;
        };

        /**
         * Restituisce true se l'utente appartiene ad una o più dei soggetti 
         * esterni forniti, false altrimenti.
         * 
         * @param {type} utente
         * @param {type} subjects
         * @returns {Boolean}
         */
        service.hasAnyExternalSubject = function (utente, subjects) {
            if (!utente || !utente.soggettiEsterni || !subjects) {
                return false;
            }
            for (var i = 0; i < subjects.length; i++) {
                var id = null;
                if (angular.isObject(subjects[i])) {
                    id = subjects[i].id;
                } else {
                    id = subjects[i];
                }
                if (utente.soggettiEsterni.indexOf(id) > -1) {
                    return true;
                }
            }
            return false;
        };
        
         /**
         * Restituisce true se l'utente appartiene ad uno o più dei settori 
         * forniti, false altrimenti.
         * 
         * @param {type} utente
         * @param {type} settori
         * @returns {Boolean}
         */
        service.hasAnySector = function (utente, sectors) {
            if (!utente || !utente.settori || !sectors) {
                return false;
            }
            for (var i = 0; i < sectors.length; i++) {
                var id = null;
                if (angular.isObject(sectors[i])) {
                    id = sectors[i].id;
                } else {
                    id = sectors[i];
                }
                if (utente.settori.indexOf(id) > -1) {
                    return true;
                }
            }
            return false;
        };

        /**
         * Restituisce true se l'utente ha ruolo settore e per uno o più
         * settori cui appartiene sono possibili le azioni specificate in
         * actions, false altrimenti. Il mapping delle azioni abilitate per
         * ciascun settore è definito da azioniSettoriById.
         * 
         * @param {type} utente
         * @param {type} actions
         * @param {type} azioniSettoriById
         * @returns {Boolean}
         */
        service.isSectorUserEnabled = function (utente, actions, azioniSettoriById) {
            if (Principal.hasAnyAuthority(['ROLE_SETTORE']) && utente.settori) {
                for (var i = 0; i < utente.settori.length; i++) {
                    var idSettore = utente.settori[i];
                    if (azioniSettoriById[idSettore]) {
                        return azioniSettoriById[idSettore].some(function (action) {
                            return actions.indexOf(action) > -1;
                        });
                    }
                }
            }
            return false;
        };

        /**
         * Restituisce true se l'utente ha ruolo commissione e all'interno
         * della commissione ha il ruolo commissionRoleName.
         * 
         * @param {type} utente
         * @param {type} commissioneRoleName
         * @returns {Boolean}
         */
        service.isCommissionRole = function (utente, commissioneRoleName) {
            return Principal.hasAnyAuthority(['ROLE_COMMISSIONE']) && utente.commissioneRuolo && utente.commissioneRuolo.nome === commissioneRoleName;
        };

        /**
         * Restituisce true se l'utente appartiene alla commissione 
         * referente, false altrimenti.
         * @param {type} utente
         * @param {type} primaryCommissionIds
         * @returns {Boolean}
         */
        service.isPrimaryCommission = function (utente, primaryCommissionIds) {
            if (utente !== null && utente.commissioni !== null && primaryCommissionIds) {
                for (var i = 0; i < utente.commissioni.length; i++) {
                    var idCommissione = utente.commissioni[i];
                    if (primaryCommissionIds.indexOf(idCommissione) > -1) {
                        return true;
                    }
                }
            }
            return false;
        };

        return service;
    }
})();
