(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('registrazione-utente', {
            parent: 'app',
            url: '/registrazione-utente',
            data: {
                authorities: [],
                pageTitle: 'gestioneAttiApp.registrazione-utente.titolo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/registrazione-utente/registrazione-utente.html',
                    controller: 'RegistrazioneUtenteController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('atto');
                    $translatePartialLoader.addPart('tipoAtto');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('form');
                    $translatePartialLoader.addPart('registrazione-utente');
                    return $translate.refresh();
                }]
            }
        }).state('registrazione-utente.new', {
              parent: 'app',
              url: '/registrazioneUtenteAtto/{id}',
              data: {
                  authorities: [],
                  pageTitle: 'gestioneAttiApp.registrazione-utente.titolo'
              },
              params: {
                id: {
                    value: null,
                    squash: true
                },
                atto: {
                    value: null,
                    squash: true
                }
                },
               onEnter: ['$mdDialog', '$stateParams', 'Atto', function ($mdDialog, $stateParams, Atto) {
                              $mdDialog.show({
                                  clickOutsideToClose: false,
                                  fullscreen: true,
                                  templateUrl: 'app/entities/registrazione-utente/registrazione-utente-dialog.html',
                                  controller: 'RegistrazioneUtenteDialogController',
                                  controllerAs: 'vm',
                                  bindToController: true,
                                  resolve: {
                                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                              $translatePartialLoader.addPart('atto');
                                              $translatePartialLoader.addPart('registrazione-utente');
                                              $translatePartialLoader.addPart('global');
                                              $translatePartialLoader.addPart('form');
                                              return $translate.refresh();
                                          }],
                                      attoId: ['$stateParams', function ($stateParams) {
                                              return $stateParams.id;
                                          }]
                                  }
                              });
                          }]
        })
        .state('cancellazione-registrazione-utente', {
            parent: 'app',
            url: '/cancellazione-registrazione-utente?registrationId&attoType&attoNumber&attoId',
            data: {
                authorities: [],
                pageTitle: 'gestioneAttiApp.registrazione-utente.titolo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/registrazione-utente/cancellazione-registrazione-utente.html',
                    controller: 'CancellazioneRegistrazioneUtenteController',
                    controllerAs: 'vm'
                }
            },
            params: {
                registrationId: {
                    value: null,
                    squash: true
                },
                attoType: {
                    value: null,
                    squash: true
                },
                attoNumber: {
                    value: null,
                    squash: true
                },
                attoId: {
                    value: null,
                    squash: true
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tipoAtto');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('form');
                    $translatePartialLoader.addPart('registrazione-utente');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
