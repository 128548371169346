(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('StatoODGDetailController', StatoODGDetailController);

    StatoODGDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'StatoODG'];

    function StatoODGDetailController($scope, $rootScope, $stateParams, previousState, entity, StatoODG) {
        var vm = this;

        vm.statoODG = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('gestioneAttiApp:statoODGUpdate', function(event, result) {
            vm.statoODG = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
