(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('view-document-tree', {
                    parent: 'entity',
                    url: '/document_tree/{legislatura}/{anno}/{tipoAtto}/{numeroAtto}/{stato}',
                    data: {
                        authorities: [],
                        pageTitle: 'gestioneAttiApp.documento-tree.title'
                    },
                    params: {
                        sezione: {
                            value: '',
                            squash: true
                        }
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/documento/documento-tree.html',
                            controller: 'DocumentoTreeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: ['$stateParams', 'Atto', function ($stateParams, Atto) {
                                return Atto.getByPk({
                                    legislatura: $stateParams.legislatura,
                                    anno: $stateParams.anno,
                                    tipoAtto: $stateParams.tipoAtto,
                                    numeroAtto: $stateParams.numeroAtto,
                                }).$promise;
                            }],

                        utente: ['Principal', function (Principal) {
                                return Principal.identity();
                            }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('archive-tree');
                                return $translate.refresh();
                            }]
                    }
                })
                .state('view-document-tree-section', {
                    parent: 'view-document-tree',
                    url: '/?sezione'
                })
                .state('view-document-content', {
                    parent: 'entity',
                    url: '/document/{documentId}/content',
                    data: {
                        authorities: [],
                        pageTitle: 'gestioneAttiApp.documento-content.title'
                    },

                    views: {
                        'content@': {
                            templateUrl: 'app/entities/documento/documento-content.html',
                            controller: 'DocumentoContentController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        entity: ['$stateParams', 'Documento', function ($stateParams, Documento) {
                                return Documento.get({id: $stateParams.documentId}).$promise;
                            }],

                        utente: ['Principal', function (Principal) {
                                return Principal.identity();
                            }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                return $translate.refresh();
                            }]
                    }
                });
    }

})();
