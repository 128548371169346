(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SoggettoEsternoController', SoggettoEsternoController);

    SoggettoEsternoController.$inject = ['SoggettoEsterno'];

    function SoggettoEsternoController(SoggettoEsterno) {

        var vm = this;

        vm.soggettoEsternos = [];

        loadAll();

        function loadAll() {
            SoggettoEsterno.query(function(result) {
                vm.soggettoEsternos = result;
                vm.searchQuery = null;
            });
        }
    }
})();
