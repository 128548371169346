(function () {

    'use strict';

    var SedutaCommonService = ['Commissione', 'SoggettoEsterno', 'User', 'toastr',
        function (Commissione, SoggettoEsterno, User, toastr) {
            var service = this;

            service.getNumeroSedutaOfWorkingBody = function(seduta, workingBody) {
                var result;
                if(seduta.numeroSeduta) return seduta.numeroSeduta;
                service.workingBody = workingBody;
                if(workingBody != null){
                    result = seduta.membriSeduta.filter(filterMembriSedutaByWorkingBody);
                    return result[0].numeroSeduta;
                }
                return -1;
            }

            // Return true if current working body is "organizzatore" for Seduta in input
            service.isOrganizzatore = function(seduta, workingBody) {
                var result;
                if(workingBody != null){
                    if(seduta.idOrganizzatore === workingBody.id) {
                        return true;
                    }
                    var membriSedutaCopy = seduta.membriSeduta.slice();
                    result = membriSedutaCopy.filter(filterMembriSedutaByOrganizzatore);
                    if(result.length == 0) return false;
                    return result[0].tipoMembro === workingBody.tipoOrganismo && result[0].idMembro === workingBody.id;
                }
                return false;
            }

            service.getPartecipantiSeduta = function(seduta) {
                var membriSedutaCopy = seduta.membriSeduta.slice();
                var result = membriSedutaCopy.filter(filterMembriSedutaByNotOrganizzatore);
                return result;
            }

            service.getOrganizzatoreSeduta = function(seduta) {
                var membriSedutaCopy = seduta.membriSeduta.slice();
                var result = membriSedutaCopy.filter(filterMembriSedutaByOrganizzatore);
                return result;
            }

            service.getNomeWorkingBody = function(workingBody){
                if(workingBody.nome != null) {
                    return workingBody.nome;
                } else {
                    if(workingBody.tipoOrganismo === 'C') {
                        Commissione.get({id: workingBody.id}, {}, function(result) {
                            return result.nome;
                        });
                    } else if(workingBody.tipoOrganismo === 'E') {
                        SoggettoEsterno.get({id: workingBody.id}, {}, function(result) {
                            return result.nome;
                        });
                    }
                }
            }

            service.fromSedutaViewToSedutaBE = function(seduta, workingBody){
                service.workingBody = workingBody;
                if(!prepareSedutaBE(seduta)) return false;
                return seduta;
            }

            service.fromSedutaBEToSedutaView = function(seduta, workingBody, organismiSeduta){
                prepareSedutaView(seduta, workingBody, organismiSeduta);
                return seduta;
            }

            service.removeWorkingBodyFromOrganismiSeduta = function(organismiSeduta, workingBody){
                // Rimuove l'organizzatore dagli organismi selezionabili come partecipanti
                for (var i = organismiSeduta.length - 1; i >= 0; --i) {
                    if (organismiSeduta[i].id === workingBody.id && organismiSeduta[i].tipoOrganismo === workingBody.tipoOrganismo) {
                        organismiSeduta.splice(i,1);
                    }
                }
                return organismiSeduta;
            }

            service.canEditSeduta = function(seduta, workingBody){

                if(service.isOrganizzatore(seduta, workingBody)){
                    if(seduta.statoCorrente.statoSeduta === 'X') return false;
                    if(seduta.statoCorrente.statoSeduta === 'P') return false;
                    return true;
                } else {
                    return false;
                }
            }

            service.canEditSedutaNumber = function(seduta, workingBody){

                if(!service.isOrganizzatore(seduta, workingBody)){
                    if(seduta.statoCorrente.statoSeduta === 'X') return false;
                    if(seduta.statoCorrente.statoSeduta === 'P') return false;
                    return true;
                } else {
                    return false;
                }
            }

            service.romanize = function(num) {
                if (!+num)
                    return false;
                var digits = String(+num).split(""),
                        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
                        roman = "",
                        i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            }

             // Filter for membri seduta that are the current working body
            function filterMembriSedutaByWorkingBody(membro){
                return membro.tipoMembro ===  service.workingBody.tipoOrganismo && membro.idMembro === service.workingBody.id;
            }

            // Filter for membri seduta that are NOT the current working body
            function filterMembriSedutaByNotWorkingBody(membro){
                return !(membro.tipoMembro ===  service.workingBody.tipoOrganismo && membro.idMembro === service.workingBody.id);
            }

            // Filter for membri seduta that are NOT Organizzatore of Seduta
            function filterMembriSedutaByNotOrganizzatore(membro){
                return membro.isOrganizzatore === false;
            }

            // Filter for membri seduta that ARE Organizzatore of Seduta
            function filterMembriSedutaByOrganizzatore(membro){
                return membro.isOrganizzatore === true;
            }

            // Convert a Seduta from view to Backend Object
            function prepareSedutaBE(seduta){

                if(!isValidSedutaMembers(seduta)) {
                    toastr.error("Nella seduta congiunta i partecipanti sono obbligatori");
                    return false;
                } else {
                    /* MEMBRO SEDUTA BE:
                    Boolean isOrganizzatore;
                    TipoOrganizzatore tipoMembro;
                    String idMembro;
                    String descrizione;
                    Integer numeroSeduta; */
                    var numeroOrganizzatore = seduta.numeroSeduta;
                    if(seduta.membriSeduta) {
                            for( var i in seduta.membriSeduta){
                                var current = seduta.membriSeduta[i];
                                if(current.id === service.workingBody.id && current.tipoOrganismo === service.workingBody.tipoOrganismo) {
                                    current.numeroSeduta = seduta.numeroSeduta;
                                    numeroOrganizzatore = null;
                                }
                                current.isOrganizzatore = false;
                                current.tipoMembro = current.tipoOrganismo;
                                current.idMembro = current.id;
                                current.descrizione = current.nome;
                            }
                    }
                    seduta.membriSeduta.push({
                        isOrganizzatore: true,
                        tipoMembro: seduta.tipoOrganizzatore,
                        idMembro: seduta.idOrganizzatore,
                        descrizione: seduta.organizzatore,
                        numeroSeduta: numeroOrganizzatore
                    });
                    return true;
                }
            }
    
            function isValidSedutaMembers(seduta){

                if(seduta.tipoSeduta === 'C') {
                    if(!seduta.membriSeduta && !seduta.partecipantiSeduta) return false;
                    if(seduta.membriSeduta.length == 0 && seduta.partecipantiSeduta.length == 0) return false;
                    if(seduta.membriSeduta.length == 1 && !seduta.membriSeduta[0]) {
                        if(!seduta.partecipantiSeduta || seduta.partecipantiSeduta.length == 0 ) {
                            return false;
                        }
                    } 
                }
                return true;
            }

            // Convert a Seduta from Backend Object to View
            function prepareSedutaView(seduta, workingBody, organismiSeduta){

                 // Informazioni organizzatore
                var organizzatore = service.getOrganizzatoreSeduta(seduta);
                if(organizzatore.length == 1) {
                    seduta.idOrganizzatore = organizzatore[0].idMembro;
                    seduta.tipoOrganizzatore = organizzatore[0].tipoMembro;
                    seduta.organizzatore = organizzatore[0].descrizione;
                }
                seduta.numeroSeduta = service.getNumeroSedutaOfWorkingBody(seduta, workingBody);

                if(!seduta.membriSeduta) {
                    seduta.membriSeduta = [];
                } else {
                    var adjMembri = [];
                    for( var i in seduta.membriSeduta) {
                        var membroCur = seduta.membriSeduta[i];
                        if(!membroCur.isOrganizzatore){
                            for(var j in organismiSeduta) {
                                var organismoCur = organismiSeduta[j];
                                if(organismoCur.tipoOrganismo === membroCur.tipoMembro && organismoCur.id === membroCur.idMembro) {
                                    organismoCur.numeroSeduta = membroCur.numeroSeduta;
                                    adjMembri.push(organismoCur);
                                }
                            }
                           
                        }
    
                    }
                    seduta.membriSeduta = adjMembri;
                }
            }

            // Restituisce true se l'anno selezionato nella tabella sedute corrisponde all'anno della data di convocazione.
            // Se l'anno non è stato selezionato ritorna true
            service.checkAnnoSedutaDataConvocazione = function(anno, seduta) {

                if(anno) {
                    var annoSeduta = seduta.dataDiConvocazione.getFullYear();
                    return annoSeduta === anno;
                }
                return true;
            }
        }];

    angular.module('gestioneAttiApp').service('SedutaCommonService', SedutaCommonService);
    
})();