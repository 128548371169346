(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('commissione-atti-giacenti', {
            parent: 'app',
            url: '/commissione-atti/giacenti?page&sort&type&term&numeroAttoSearch&idCommissione',
            data: {
                authorities: [],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commissione-atti/commissione-atti.html',
                    controller: 'CommissioneAttiController',
                    controllerAs: 'vm'
                },
                'navbar@' : {
                    templateUrl: 'app/layouts/navbar/navbar-commission-pages.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'numeroAtto,desc',
                    squash: true
                },
                type: {
                    value: null,
                    squash:true
                },
                term: {
                    value: null,
                    squash:true
                },
                legislatura: {
                    value: '11',
                    squash:false
                },
                numeroAttoSearch: {
                    value: null,
                    squash: true
                },
                idCommissione: {
                    value: null,
                    squash: true
                }
            },
            onEnter: ['$state', 'Principal', '$timeout', function ($state, Principal, $timeout) {
                // if (Principal.isAuthenticated()) {
                //     $timeout(function () {
                //         $state.go('home_logged', {}, {reload: true});
                //     }, 0);
                // }
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('commissioneAtti'); /* TODO */
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('atto');
                    $translatePartialLoader.addPart('tipoAtto');
                    $translatePartialLoader.addPart('tipoProponente');
                    $translatePartialLoader.addPart('form');
                    return $translate.refresh();
                }],
                pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        type: $stateParams.type,
                        term: $stateParams.term,
                        legislatura: $stateParams.legislatura,
                        numeroAttoSearch: $stateParams.numeroAttoSearch,
                        idCommissione: $stateParams.idCommissione,
                        tipoPaginaCommissione: '1'
                    };
                }]
            }
        })
        .state('commissione-atti-licenziati', {
            parent: 'app',
            url: '/commissione-atti/licenziati?page&sort&type&term&numeroAttoSearch&idCommissione',
            data: {
                authorities: [],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commissione-atti/commissione-atti.html',
                    controller: 'CommissioneAttiController',
                    controllerAs: 'vm'
                },
                'navbar@' : {
                    templateUrl: 'app/layouts/navbar/navbar-commission-pages.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'numeroAtto,desc',
                    squash: true
                },
                type: {
                    value: null,
                    squash:true
                },
                term: {
                    value: null,
                    squash:true
                },
                legislatura: {
                    value: '11',
                    squash:false
                },
                numeroAttoSearch: {
                    value: null,
                    squash: true
                },
                idCommissione: {
                    value: null,
                    squash: true
                }
            },
            onEnter: ['$state', 'Principal', '$timeout', function ($state, Principal, $timeout) {
                // if (Principal.isAuthenticated()) {
                //     $timeout(function () {
                //         $state.go('home_logged', {}, {reload: true});
                //     }, 0);
                // }
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('commissioneAtti'); /* TODO */
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('atto');
                    $translatePartialLoader.addPart('tipoAtto');
                    $translatePartialLoader.addPart('tipoProponente');
                    $translatePartialLoader.addPart('form');
                    return $translate.refresh();
                }],
                pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        type: $stateParams.type,
                        term: $stateParams.term,
                        legislatura: $stateParams.legislatura,
                        numeroAttoSearch: $stateParams.numeroAttoSearch,
                        idCommissione: $stateParams.idCommissione,
                        tipoPaginaCommissione: '2'
                    };
                }]
            }
        });
    }

})();
