(function () {
    'use strict';
    angular
            .module('gestioneAttiApp')
            .factory('TipoDocumento', TipoDocumento);

    TipoDocumento.$inject = ['$resource'];

    function TipoDocumento($resource) {
        var resourceUrl = 'api/:public/tipo-documentos/:id/:seduta/:ordineDelGiorno';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'tipiDocumentoSeduta' : {
                method : 'GET',
                params : {
                    public: 'public',
                    seduta : 'seduta'
                },
                isArray : true
            },
            'tipiDocumentoOdg' : {
                method : 'GET',
                params : {
                    public: 'public',
                    ordineDelGiorno : 'ordine-del-giorno'
                },
                isArray : true
            }
        });
    }
})();
