(function () {
    'use strict';

    angular
            .module('gestioneAttiApp', [
                'ngStorage',
                'tmh.dynamicLocale',
                'pascalprecht.translate',
                'ngSanitize',
                'ngMaterial',
                'ngAnimate',
                'ngAria',
                'ngResource',
                'ngCookies',
                'ngCacheBuster',
                'ngFileUpload',
                'ui.bootstrap',
                'ui.bootstrap.datetimepicker',
                'ui.router',
                'infinite-scroll',
                // jhipster-needle-angularjs-add-module JHipster will add new module here
                'angular-loading-bar',
                'toastr',
                'hl.sticky',
                'ngFileSaver',
                //'angularSlideables',
                'angular.filter',
                'ui.tree',
                'ngMessages'
            ])
            .run(run)
            .config(config)
            .config(dateFormat);

    run.$inject = ['stateHandler', 'translationHandler', '$state'];
    config.$inject = ['toastrConfig', '$mdThemingProvider'];
    dateFormat.$inject = ['$mdDateLocaleProvider'];

    function run(stateHandler, translationHandler, $state) {
        stateHandler.initialize();
        translationHandler.initialize();
        $state.go('home');
    }

    function config(toastrConfig, $mdThemingProvider) {

        angular.extend(toastrConfig, {
            //autoDismiss: false,
            //containerId: 'toast-container',
            allowHtml: true,
            closeButton: true,
            maxOpened: 3,
            progressBar: true,
            tapToDismiss: true,
            newestOnTop: true,
            timeOut: 2000,
            positionClass: 'toast-top-right',
            //preventDuplicates: true,
            preventOpenDuplicates: true,
            target: 'body'
        });

        $mdThemingProvider.theme('default')
                .primaryPalette('red')
                .accentPalette('orange');
    }

    function dateFormat($mdDateLocaleProvider) {
        $mdDateLocaleProvider.formatDate = function (date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY');
            } else {
                return null;
            }
        }
    }


    angular.module('gestioneAttiApp').constant('GESTIONE_ATTI_DOC_VISIBILITIES', [
        {
            value: 'PUBLIC',
            textKey: 'documentToolbar.visibilityPublic',
            onlyForCommission: false
        },
        {
            value: 'INTRANET',
            textKey: 'documentToolbar.visibilityIntranet',
            onlyForCommission: false
        },
        {
            value: 'COMMISSION_MEMBER',
            textKey: 'documentToolbar.visibilityCommissionMember',
            onlyForCommission: true
        },
        {
            value: 'COMMISSION_PRESIDENT',
            textKey: 'documentToolbar.visibilityCommissionPresident',
            onlyForCommission: true
        }
    ]);


    angular.module('gestioneAttiApp').constant('AUTHORITIES', [
        {
            name: 'ROLE_ADMIN',
            textKey: 'authority.roleAdmin'
        },
        {
            name: 'ROLE_SETTORE',
            textKey: 'authority.roleSettore'
        },
        {
            name: 'ROLE_COMMISSIONE',
            textKey: 'authority.roleCommissione'
        },
        {
            name: 'ROLE_AULA',
            textKey: 'authority.aula'
        },
        {
            name: 'ROLE_ENTE',
            textKey: 'authority.ente'
        },
        {
            name: 'ROLE_EXTERNAL',
            textKey: 'authority.external'
        },
        {
            name: 'ROLE_PROTOCOLLO',
            textKey: 'authority.protocollo'
        }
    ]);

    angular.module('gestioneAttiApp').constant('TIPO_ANAGRAFICA_ITERLEGIS', [
        {
            name: 'ENTE'
        },
        {
            name: 'ORGANO'
        }
    ]);

    // Admin has permission for all resources
    angular.module('gestioneAttiApp').constant('RESOURCE_PERMISSIONS', [
        {
            name: 'ROLE_SETTORE',
            textKey: 'authority.roleSettore'
        },
        {
            name: 'ROLE_COMMISSIONE',
            textKey: 'authority.roleCommissione'
        },
        {
            name: 'ROLE_AULA',
            textKey: 'authority.roleAula'
        },
        {
            name: 'ROLE_ENTE',
            textKey: 'authority.roleEnte'
        },
        {
            name: 'ROLE_EXTERNAL',
            textKey: 'authority.roleExternal'
        },
        {
            name: 'ROLE_PROTOCOLLO',
            textKey: 'authority.protocollo'
        }
    ]);


    angular.module('gestioneAttiApp').constant('MAX_UPLOAD_SIZE', 50728640); //50MB Richiesta Caldini aumentato da 15MB

    angular.module('gestioneAttiApp').constant('SEARCH_STATES', [
        {
            value: 'PRESENTATO',
            name: 'gestioneAttiApp.atto.statoQuery.Presentato'
        },
        {
            value: 'COMMISSIONE',
            name: 'gestioneAttiApp.atto.statoQuery.Commissione'
        },
        {
            value: 'AULA',
            name: 'gestioneAttiApp.atto.statoQuery.Aula'
        },
        {
            value: 'APPROVATO',
            name: 'gestioneAttiApp.atto.statoQuery.Approvato'
        },
        {
            value: 'RESPINTO',
            name: 'gestioneAttiApp.atto.statoQuery.Respinto'
        },
        {
            value: 'DECADUTO',
            name: 'gestioneAttiApp.atto.statoQuery.Decaduto'
        },
        {
            value: 'IMPROCEDIBILE',
            name: 'gestioneAttiApp.atto.statoQuery.Improcedibile'
        },
        {
            value: 'RITIRATO',
            name: 'gestioneAttiApp.atto.statoQuery.Ritirato'
        },
        {
            value: 'SVOLTA',
            name: 'gestioneAttiApp.atto.statoQuery.Svolta'
        },
        {
            value: 'TRASMISSIONE_GIUNTA',
            name: 'gestioneAttiApp.atto.statoQuery.TrasmissioneGiunta'
        },
        {
            value: 'RISPOSTA_SCRITTA',
            name: 'gestioneAttiApp.atto.statoQuery.RispostaScritta'
        },
        {
            value: 'INAMMISSIBILE',
            name: 'gestioneAttiApp.atto.statoQuery.Inammissibile'
        },
        {
            value: 'TRASFERITA_COMPETENZA',
            name: 'gestioneAttiApp.atto.statoQuery.TrasferitaCompetenza'
        }
    ]);

    angular.module('gestioneAttiApp').constant('ACT_TYPES', [
        {
            value: 'PDL',
            name: 'gestioneAttiApp.atto.type.pdl'
        },
        {
            value: 'PDD',
            name: 'gestioneAttiApp.atto.type.pdd'
        },
        {
            value: 'PLIP',
            name: 'gestioneAttiApp.atto.type.plip'
        },{
            value: 'PDLP',
            name: 'gestioneAttiApp.atto.type.pdlp'
        },
        {
            value: 'PDLS',
            name: 'gestioneAttiApp.atto.type.pdls'
        },
/*         {
            value: 'PDR',
            name: 'gestioneAttiApp.atto.type.pdr'
        }, */
        {
            value: 'ODG',
            name: 'gestioneAttiApp.atto.type.odg'
        },
        {
            value: 'MOZIONE',
            name: 'gestioneAttiApp.atto.type.mozione'
        },
        {
            value: 'PEA',
            name: 'gestioneAttiApp.atto.type.pea'
        },
        {
            value: 'IO',
            name: 'gestioneAttiApp.atto.type.io'
        },
        {
            value: 'IS',
            name: 'gestioneAttiApp.atto.type.is'
        },
        {
            value: 'CDG',
            name: 'gestioneAttiApp.atto.type.cdg'
        },
        {
            value: 'PEL',
            name: 'gestioneAttiApp.atto.type.pel'
        },
        {
            value: 'IEA',
            name: 'gestioneAttiApp.atto.type.iea'
        },
        {
            value: 'DEA',
            name: 'gestioneAttiApp.atto.type.dea'
        },
        {
            value: 'PDRIS',
            name: 'gestioneAttiApp.atto.type.pdris'
        },
        {
            value: 'REL',
            name: 'gestioneAttiApp.atto.type.rel'
        },
        {
            value: 'RINT',
            name: 'gestioneAttiApp.atto.type.rint'
        }
    ]);

    angular.module('gestioneAttiApp').constant('EVENTI_NOTIFICA_ATTO', [
        {
            value: 'PRESENTAZIONE',
            name: 'gestioneAttiApp.atto.evento-notifica.presentazione'
        },
        {
            value: 'ASSEGNAZIONE',
            name: 'gestioneAttiApp.atto.evento-notifica.assegnazione'
        },
        {
            value: 'PASSAGGIO_IN_AULA',
            name: 'gestioneAttiApp.atto.evento-notifica.passaggio-aula'
        }
    ]);

    angular.module('gestioneAttiApp').constant('TIPOLOGIA_REPORT', [
        'DEFAULT',
        'COMMISSIONE'
    ]);

    angular.module('gestioneAttiApp').constant('TIPOLOGIA_ORDINAMENTO_REPORT', [
         'SCADENZA_ADEMPIMENTO',
         'DESTINATARIO_ADEMPIMENTO',
         'MACRO_MATERIA'
    ]);

    /*
    * 2023-06-05 Usata per disabilitare le opzioni "Protocolla al passaggio di stato" e "Protocolla immediatamente"
    * Inserire false quando verrà abilitata la protocollazione JOSH
    */
    angular.module('gestioneAttiApp').constant('PROTOCOLLAZIONE_DISABILITATA', true);

    angular.module('gestioneAttiApp').constant('ATTI_TABLE_TYPE', {
        'ATTI' : 0,
        'COMMISSIONE_ESAME' : 1,
        'COMMISSIONE_ESAMINATI' : 2
    });

    angular.module('gestioneAttiApp').constant('TIPO_NUMERAZIONE_SEDUTA', {
        LEGISLATURA: 'LEGISLATURA',
        ANNO: 'ANNO'
    });

})();





