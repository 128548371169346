(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('PuntoODGDeleteController',PuntoODGDeleteController);

    PuntoODGDeleteController.$inject = ['$uibModalInstance', '$stateParams', 'puntiODG', 'puntoODGToDelete', 'PuntoODG'];

    function PuntoODGDeleteController($uibModalInstance, $stateParams, puntiODG, puntoODGToDelete, PuntoODG) {
        var vm = this;

        vm.idODG = $stateParams.idODG;
        vm.idSeduta = $stateParams.idSeduta;
        vm.puntoODGToDelete = puntoODGToDelete;
        vm.puntiODG = puntiODG;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            vm.progressivoODGToDelete = puntoODGToDelete.progressivoPuntoODG;

            // Correggo i progressivi dei punti ODG successivi a quello eliminato
            /*vm.puntiODG.forEach(function (p) {
                if(p.progressivoPuntoODG > vm.progressivoODGToDelete) {
                    p.progressivoPuntoODG --;
                    PuntoODG.update({idODG: vm.idODG}, p);
                }
            });*/

            // Elimino il punto ODG (e riassegno i progressivi da BE)
            PuntoODG.delete(
                {idPuntoODG: vm.puntoODGToDelete.id, idODG: vm.idODG, idSeduta: vm.idSeduta}, 
                function () {$uibModalInstance.close(true);}
            );
        }
    }
})();
