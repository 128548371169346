(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SedutaSelectWorkingController', SedutaSelectWorkingController);

    SedutaSelectWorkingController.$inject = ['$timeout', '$mdDialog', '$state', 'Seduta', 'legislature', 'legislatura', 'toastr', '$rootScope', 'TIPO_NUMERAZIONE_SEDUTA'];

    function SedutaSelectWorkingController ($timeout, $mdDialog, $state, Seduta, legislature, legislatura, toastr, $rootScope, TIPO_NUMERAZIONE_SEDUTA) {
        var vm = this;

        vm.workingBody = null;
        vm.clear = clear;
        vm.save = save;
        vm.legislatura = legislatura;
        vm.legislature = legislature;
        vm.TIPO_NUMERAZIONE_SEDUTA = TIPO_NUMERAZIONE_SEDUTA;
        vm.years = [];
        vm.selectedYear = null;

        initController();

        Seduta.organismiUtente({}, function(result) {
            vm.organismi = result;
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function initController() {
            var currentYear = new Date().getFullYear();
            vm.selectedYear = currentYear;
            // Genera le opzioni degli anni dal 2022 all'anno attuale
            for (var year = currentYear + 1; year >= 2022; year--) {
                vm.years.push(year);
            }

        }

        function clear () {
            $mdDialog.cancel();
            $state.go('home');
        }

        function save () {
            vm.isSaving = true;

            if (validForm()) {
            //if (vm.workingBody !== null && vm.legislatura !== null) {
                $mdDialog.cancel();
                $state.go('seduta', {
                    idOrg: vm.workingBody.id, 
                    typeOrg : vm.workingBody.tipoOrganismo, 
                    nameOrg: vm.workingBody.nome, 
                    legislatura : vm.legislatura,
                    anno: vm.selectedYear} , {reload: false});
            } else {
                vm.isSaving = false;
                toastr.error("Parametri obbligatori non inseriti");
            }
            
        }

        function validForm() {
            if(vm.workingBody === null) return false;
            if(vm.workingBody.tipoNumerazioneSeduta === TIPO_NUMERAZIONE_SEDUTA.ANNO) {
                return !(vm.selectedYear === null || vm.selectedYear === undefined || vm.selectedYear === "");
            } else if (vm.workingBody.tipoNumerazioneSeduta === TIPO_NUMERAZIONE_SEDUTA.LEGISLATURA) {
                vm.selectedYear = null;
                return !(vm.legislatura === null || vm.legislatura === undefined);
            } else {
                vm.selectedYear = null;
                return !(vm.legislatura === null || vm.legislatura === undefined);
            }
        }

        
    }
})();