(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SincronizzatoreAnagraficheController', SincronizzatoreAnagraficheController);

    SincronizzatoreAnagraficheController.$inject = ['$state', 'SincronizzatoreAnagrafiche', 'runningSyncro', 'toastr', 'latestSyncro'];

    function SincronizzatoreAnagraficheController($state, SincronizzatoreAnagrafiche, runningSyncro, toastr, latestSyncro) {
        var vm = this;
        vm.anagraficTypeCodes = ['UFF', 'EST' , 'DIP'];
        vm.selectedCode = null;
        vm.runningSyncro = runningSyncro;
        vm.latestSyncro = latestSyncro;

        vm.clickStart = function() {
            if(vm.selectedCode === null){
                toastr.error("Devi specificare il tipo anagrafica");
                return;
            }
            SincronizzatoreAnagrafiche.startSyncro({anagraficTypeCode: vm.selectedCode}, {}, onSuccess, onError);
        };

        function onSuccess(result){
            vm.runningSyncro.push(result);
        }

        function onError(error) {
            console.log("Failure!", error);
            toastr.error(error.data.message);
        }
    }
})();