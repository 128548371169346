/**
 * Created by Michele Romani on 22/06/2017.
 */
(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .controller('HomeLoggedController', HomeLoggedController);

    HomeLoggedController.$inject = ['$scope', 'Principal', 'LoginService', 'pagingParams'];

    function HomeLoggedController($scope, Principal, LoginService, pagingParams) {
        var vm = this;

        vm.pagingParams = pagingParams;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

    }
})();
