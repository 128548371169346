/**
 * Created by Michele Romani on 22/06/2017.
 */


angular.module('gestioneAttiApp').factory('MockDataFunctionsFactory', function() {
    console.log("Building MockDataFunctionsFactory");

    var MockDataFunctionsFactory = {

        getPdlList : function(){
            return [
                {
                    "id": 1,
                    "title" : "Disposizioni per favorire la trasparenza tra le Società Cooperative e la Pubblica Amministrazione.",
                    "date" : "25/06/2015",
                    "prot" : "15138",
                    "proposer" : "Donzelli,Vescovi,Borghi",
                    "state" : "new"
                },
                {
                    "id": 2,
                    "title" : "Rendiconto generale per l'anno finanziario 2014.",
                    "date" : "02/07/2015",
                    "prot" : "15852",
                    "proposer" : "GR-Bugli",
                    "state" : "approved"
                },
                {
                    "id": 3,
                    "title" : "Bilancio di previsione per l’esercizio finanziario 2015 e pluriennale 2015 – 2017 e bilancio di previsione conoscitivo 2015 – 2017. Assestamento.",
                    "date" : "22/07/2015",
                    "prot" : "15910",
                    "proposer" : "GR-Bugli",
                    "state" : "rejected"
                },
                {
                    "id": 4,
                    "title" : "Disciplina dello stemma, del gonfalone, del sigillo e della fascia della Regione Toscana.",
                    "date" : "14/07/2015",
                    "prot" : "17168",
                    "proposer" : "Giani, De Robertis, Donzelli",
                    "state" : "rejected"
                },
                {
                    "id": 5,
                    "title" : "Comitato regionale delle associazioni e delle manifestazioni di rievocazione e ricostruzione storica. Modifiche alla Lr.5/2012.",
                    "date" : "17/07/2015",
                    "prot" : "17171",
                    "proposer" : "GR-Bugli",
                    "state" : "approved"
                },
                {
                    "id": 6,
                    "title" : "Modifiche alla legge regionale 8 gennaio 2009 n. 1 (Testo unico in materia di organizzazione e ordinamento del personale).",
                    "date" : "07/07/2015",
                    "prot" : "17173",
                    "proposer" : "Giani, De Robertis, Donzelli, Mazzeo",
                    "state" : "approved"
                }
            ]
        }

    };
    return MockDataFunctionsFactory;
});
