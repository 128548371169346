(function () {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .directive('isIntranet', isIntranet);

    isIntranet.$inject = ['$http', 'Principal'];

    function isIntranet($http, Principal) {
        var directive = {         
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {

            var deleteElement = function () {
                element.remove();
            },
            onSuccess = function () {
            },
            onError = function (error) {
                if(error.status === 403){
                    deleteElement();
                } 
            },
            defineVisibility = function () {
                $http.get('/api/internal/check').then(onSuccess, onError);
            }; 

            if (attrs.isIntranet === "true") {
                defineVisibility();

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility();
                });
            }

        }

       
    }

})();