(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('sincronizzatore-anagrafiche', {
                    parent: 'account',
                    url: '/sincronizzatore-anagrafiche',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_PROTOCOLLO'],
                        pageTitle: 'sincronizzatore.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/SincronizzatoreAnagrafiche/sincronizzatore-anagrafiche.html',
                            controller: 'SincronizzatoreAnagraficheController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');

                                return $translate.refresh();
                            }],
                        runningSyncro: ['SincronizzatoreAnagrafiche', function (SincronizzatoreAnagrafiche) {
                                return SincronizzatoreAnagrafiche.getRunningSyncro().$promise;
                            }],
                        latestSyncro: ['SincronizzatoreAnagrafiche', function (SincronizzatoreAnagrafiche) {
                            return SincronizzatoreAnagrafiche.getLatestSyncro().$promise;
                        }],     
                    }
                })
            }
})();