(function () {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/home?page&sort&type&state&term&legislatura&numeroAtto&numeroLegge&dataPromulgazione',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'numeroAtto,desc',
                        squash: true
                    },
                    type: {
                        value: null,
                        squash:true
                    },
                    state: {
                        value: null,
                        squash:true
                    },
                    term: {
                        value: null,
                        squash:true
                    },
                    legislatura: {
                        value: '11',
                        squash:false
                    },
                    numeroAttoSearch: {
                        value: null,
                        squash: true
                    },
                    numeroLegge: {
                        value: null,
                        squash: true
                    },
                    dataPromulgazione: {
                        value: null,
                        squash: true
                    }
                },
            onEnter: ['$state', 'Principal', '$timeout', function ($state, Principal, $timeout) {
                if (Principal.isAuthenticated()) {
                    $timeout(function () {
                        $state.go('home_logged', {}, {reload: true});
                    }, 0);
                }
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('atto');
                    $translatePartialLoader.addPart('tipoAtto');
                    $translatePartialLoader.addPart('tipoProponente');
                    $translatePartialLoader.addPart('form');
                    return $translate.refresh();
                }],
                pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        type: $stateParams.type,
                        state: $stateParams.state,
                        term: $stateParams.term,
                        legislatura: $stateParams.legislatura,
                        numeroAttoSearch: $stateParams.numeroAttoSearch,
                        numeroLegge: $stateParams.numeroLegge,
                        dataPromulgazione: $stateParams.dataPromulgazione
                    }
                }]
            }
        });
    }
})();
