(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('ImporterBoardMonitor', ImporterBoardMonitor);

        ImporterBoardMonitor.$inject = ['$resource'];

    function ImporterBoardMonitor ($resource) {
        var resourceUrl =  'api/:public/sincronizzatore-anagrafiche/:id/:latest';

        var resource = $resource(resourceUrl, {}, {
            'queryPaged': {
                method: 'GET',
                isArray: true,
                url: 'api/public/paged/protocol-import-details'
            },
            'retryImport': {
                method: 'POST',
                url: 'api/protocol-import-details/retry-import'
            }
        });

        resource.errorCodeToString = function(errorCode, metadati) {
            switch(errorCode) {
                case 'PRATICA_ASSENTE': 
                    return 'Non è stata trovata nessuna pratica associata.';
                case 'DETTAGLI_DOSSIER_NON_RICEVUTI': 
                    return 'Nessun dettaglio della pratica ricevuto da protocollo.';
                case 'DATI_NON_COMPLETI': 
                    return 'I dati numero atto e/o legistlatura e/o anno sono incompleti.';
                case 'TIPO_PROPONENTE_SCONOSCIUTO':
                    var codiciAnagrafiche = 'Codici Anagrafiche ricevute: [';
                    if(!metadati || !metadati.recipientList || metadati.recipientList.length === 0){
                        return 'Tipo proponente non riconosciuto. ' + codiciAnagrafiche + ']';
                    }

                    for(var i = 0; i < metadati.recipientList.length; i++) {
                        codiciAnagrafiche = codiciAnagrafiche + metadati.recipientList[i].code;
                        if(i === metadati.recipientList.length - 1) {
                            codiciAnagrafiche = codiciAnagrafiche + ']';
                        } else {
                            codiciAnagrafiche = codiciAnagrafiche + ',';
                        }
                    }
                    return 'Tipo proponente non riconosciuto. ' + codiciAnagrafiche;
                case 'IMPOSSIBILE_RECUPERARE_PROPONENTE': 
                    return 'Impossibile recuperare i dati di anagrafica di uno dei proponenti.';
                case 'CODICE_SEZIONE_SCONOSCIUTO':
                    if(!metadati || !metadati.mnemonicList || !metadati.mnemonicList.length === 0) {
                        return 'Codice mnemonico di sezione non riconosciuto.';
                    }
                    var listaCodici = '[';
                    metadati.mnemonicList.forEach(function(mnemonic, index){
                        if(mnemonic.typeCode) {
                            listaCodici = listaCodici + mnemonic.typeCode;
                            if(index === metadati.mnemonicList.length - 1){
                                listaCodici = listaCodici + ']'
                            } else {
                                listaCodici = listaCodici + ',';
                            }
                        } else {
                            listaCodici = mnemonic; 
                        }
                    });
                    return 'Codice mnemonico di sezione non riconosciuto. ' + listaCodici;
                case 'CODICE_DOCUMENTO_SCONOSCIUTO':
                    return 'Codice mnemonico di documento non riconosciuto.';
                case 'SUCCESS': 
                    return '';
                case 'LISTA_DOSSIER_VUOTA':
                    return 'Lista dossier vuota.';
                case 'XML_PARSE_ERROR':
                    return 'Errore nel ricevimento del dettaglio di protocollo (Errore XML).';
                case 'CONTROLLO_IMPORT_DOCUMENTI_FALLITO':
                    return 'Errore generico di controllo presenza documenti.';
                case 'DOCUMENTO_NON_SALVATO_DB':
                    return 'Errore controllo presenza documenti - Documento non presente su DB';
                case 'DOCUMENTO_NON_SALVATO_FS':
                    return 'Errore controllo presenza documenti - Documento non presente su file system';
                case 'DOCUMENTO_NON_AGGIORNATO_FS':
                    return 'Errore controllo presenza documenti - Documento non aggiornato su file system';
                case 'IMPORT_STARTED':
                    return 'Importazione iniziata ma non ancora terminata.';
                case 'IMPORT_NOT_ENABLED':
                        return 'Flag di importazione non abilitato. Protocollo non elaborato.';
                case 'DETTAGLI_DOCUMENTO_NON_RICEVUTI':
                    return 'Nessun dettaglio del documento ricevuto da protocollo.';
                case 'PROPRIETA_AGGIUNTIVE_DOCUMENTO_NON_RICEVUTE':
                    return 'Proprietà aggiuntive DOCUMENTO non complete.';
                case 'DETTAGLI_FASCICOLO_NON_RICEVUTI':
                    return 'Nessun dettaglio del fascicolo ricevuto da protocollo.';
                case 'PROPRIETA_AGGIUNTIVE_FASCICOLO_NON_RICEVUTE':
                    return 'Proprietà aggiuntive FASCICOLO non complete.';
                case 'FILES_NON_RICEVUTI':
                        return 'Files allegati al documento non ricevuti da protocollo.';
                case 'ALTRO': 
                    return "Errore generico, contattare l'amministratore di sistema.";
            }

        }

        resource.getErrorTypeString = function (errorType) {
            switch(errorType) {
                case 'SUCCESS':
                    return 'IMPORTATO';
                case 'IMPORT_STARTED':
                    return 'IMPORT INIZIATO';
                default:
                    return 'ERRORE:'
            }
        }

        return resource;

    }
})();