/**
 * Created by Michele Romani on 27/06/2017.
 */
(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('FormController', FormController);

    FormController.$inject = ['$scope', 'Principal', 'Atto'];

    function FormController ($scope, Principal, Atto) {
        var vm = this;
        $scope.sezioni = Atto.getSezioni({id: "4"});
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
