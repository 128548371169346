(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('CancellazioneRegistrazioneUtenteController', CancellazioneRegistrazioneUtenteController);

        CancellazioneRegistrazioneUtenteController.$inject = ['$state', 'toastr', 'ACT_TYPES', 'Atto', 'RegistrazioneUtente', '$stateParams'];

    function CancellazioneRegistrazioneUtenteController($state, toastr, ACT_TYPES, Atto, RegistrazioneUtente, $stateParams) {
        var vm = this;
        vm.loadingDettaglioRegistrazione = true;
        vm.dettaglioRegistrazione;
        vm.idAttoButtonClicked = false;
        vm.tipoAttoButtonClicked = false;
        
        if($stateParams.attoType && $stateParams.registrationId && $stateParams.attoId) {
            vm.registrationId = $stateParams.registrationId;
            vm.attoType = $stateParams.attoType;
            vm.attoId = $stateParams.attoId;
            vm.attoNumber = $stateParams.attoNumber;
            vm.attoTypeName = ACT_TYPES[ACT_TYPES.findIndex(function(tipoAtto) {return tipoAtto.value === vm.attoType;})].name;

            
            RegistrazioneUtente.getDettaglioRegistrazioneUtenteByAttoIdAndTipoAtto({id: vm.registrationId, attoId: vm.attoId, tipoAtto: vm.attoType},
                function(result) {
                    vm.dettaglioRegistrazione = result;
                    vm.loadingDettaglioRegistrazione = false;
                },
                function(error) {
                    toastr.error("Impossibile cancellare la registrazione: nessuna registrazione trovata.")
                    vm.loadingDettaglioRegistrazione = false;
                });
        } else {
            vm.loadingDettaglioRegistrazione = false;
        }
        
        vm.onConfirmClickIdAtto = function() {
            vm.idAttoButtonClicked = true;
            RegistrazioneUtente.deleteRegistrazioneAtto({id: vm.registrationId, idAtto: vm.attoId}, {},
                function(result) {
                    toastr.success('Registrazione cancellata con successo!');
                },
                function(error) {
                    toastr.error('Errore durante la cancellazione della registrazione. Si prega di riprovare.');
                    vm.idAttoButtonClicked = false;
                });
        }

        vm.onConfirmClickTipoAtto = function() {
            vm.tipoAttoButtonClicked = true;
            RegistrazioneUtente.deleteRegistrazioneAtto({id: vm.registrationId, tipoAtto: vm.attoType}, {},
                function(result) {
                    toastr.success('Registrazione cancellata con successo!');
                },
                function(error) {
                    toastr.error('Errore durante la cancellazione della registrazione. Si prega di riprovare.');
                    vm.tipoAttoButtonClicked = false;
                });
        }
    }
})();
