(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('CommissioneController', CommissioneController);

    CommissioneController.$inject = ['Commissione'];

    function CommissioneController(Commissione) {

        var vm = this;

        vm.commissiones = [];

        loadAll();

        function loadAll() {
            Commissione.query(function(result) {
                vm.commissiones = result;
                vm.searchQuery = null;
            });
        }
    }
})();
