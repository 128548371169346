(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('TipoDocumentoDetailController', TipoDocumentoDetailController);

    TipoDocumentoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TipoDocumento'];

    function TipoDocumentoDetailController($scope, $rootScope, $stateParams, previousState, entity, TipoDocumento) {
        var vm = this;

        vm.tipoDocumento = entity;
        vm.previousState = previousState.name;

        vm.hasAuthority = function (authority) {
            if (vm.tipoDocumento.authorities.length === 0) return true;

            for (var i = 0; i < vm.tipoDocumento.authorities.length; i++) {
                var auth = vm.tipoDocumento.authorities[i];
                if (auth.name === authority) {
                    return true;
                }
            }
            return false;
        };

        var unsubscribe = $rootScope.$on('gestioneAttiApp:tipoDocumentoUpdate', function(event, result) {
            vm.tipoDocumento = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
