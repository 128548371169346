(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('DirezioneDeleteController',DirezioneDeleteController);

    DirezioneDeleteController.$inject = ['$uibModalInstance', 'entity', 'Direzione'];

    function DirezioneDeleteController($uibModalInstance, entity, Direzione) {
        var vm = this;

        vm.direzione = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Direzione.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
