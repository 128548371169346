(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SettoreDeleteController',SettoreDeleteController);

    SettoreDeleteController.$inject = ['$uibModalInstance', 'entity', 'Settore'];

    function SettoreDeleteController($uibModalInstance, entity, Settore) {
        var vm = this;

        vm.settore = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Settore.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
