(function () {
    'use strict';

    angular
            .module('gestioneAttiApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
                .state('importer-board-monitor', {
                    parent: 'app',
                    url: '/importer-board-monitor?page&protocolSystem&protocolNumber&importType&direction&errorType',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_PROTOCOLLO'],
                        pageTitle: 'importer-board-monitor.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/importer-board-monitor/importer-board-monitor.html',
                            controller: 'ImporterBoardMonitorController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'latestImportDate,desc',
                            squash: true
                        },
                        protocolSystem: {
                            value: null,
                            squash:true
                        },
                        protocolNumber: {
                            value: null,
                            squash:true
                        },
                        importType: {
                            value: null,
                            squash:true
                        },
                        direction: {
                            value: null,
                            squash:true
                        },
                        errorType: {
                            value: null,
                            squash: true
                        },
                        year: {
                            value: null,
                            squash: true
                        },
                        attoNumber: {
                            value: null,
                            squash: true
                        },
                        legislatura: {
                            value: null,
                            squash: true
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('tipoAtto');
                                $translatePartialLoader.addPart('tipoProponente');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                $translatePartialLoader.addPart('addable-input');
                                $translatePartialLoader.addPart('document-toolbar');
                                $translatePartialLoader.addPart('archive-tree');
                                $translatePartialLoader.addPart('commission-opinion');
                                $translatePartialLoader.addPart('emendments');
                                $translatePartialLoader.addPart('sectionNotes');

                                return $translate.refresh();
                            }],
                        pagingParams: ['PaginationUtil', '$stateParams', function (PaginationUtil, $stateParams) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                protocolSystem: $stateParams.protocolSystem,
                                protocolNumber: $stateParams.protocolNumber,
                                importType: $stateParams.importType,
                                direction: $stateParams.direction,
                                errorType: $stateParams.errorType,
                                year: $stateParams.year,
                                attoNumber: $stateParams.attoNumber,
                                legislatura: $stateParams.legislatura
                            }
                        }]
                    }
                })
            }
})();