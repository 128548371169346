(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('CommissioneAttiController', CommissioneAttiController);

    CommissioneAttiController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$stateParams', 'PaginationUtil', '$translate', 'pagingParams', 'Commissione', 'toastr', '$location', 'ATTI_TABLE_TYPE'];

    function CommissioneAttiController($scope, Principal, LoginService, $state, $stateParams, PaginationUtil, $translate, pagingParams, Commissione, toastr, $location, ATTI_TABLE_TYPE) {
        $translate.refresh();
        var vm = this;

        vm.pagingParams = pagingParams;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register () {
            $state.go('register');
        }

        //http://localhost:8081/#/commissione-atti/giacenti?idCommissione=5a534ec3546803124d2e9782
        //http://localhost:8081/#/commissione-atti/licenziati?idCommissione=5a534ec3546803124d2e9782

        var params = $location.search();

        vm.idCommissione = angular.isUndefined(params.idCommissione) ? 0 : params.idCommissione;
        vm.legislatura = '11';
        vm.tipoPaginaCommissione = vm.pagingParams.tipoPaginaCommissione;

        if (vm.idCommissione <= 0) {
            toastr.error('Identificativo commissione non valido');
            return; //TODO: Redirect 404?
        }

        if (vm.legislatura === '') {
            toastr.error('Numero legislatura non valido');
            return; //TODO: Redirect 404?
        }

        vm.commissione = {};
        loadCommissione();

        function loadCommissione() {
            Commissione.get({id: vm.idCommissione}, {}, function(result) {
                vm.commissione = result;
            });
        }

    }
})();
