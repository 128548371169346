(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('PuntoODG', PuntoODG);

    PuntoODG.$inject = ['$resource'];

    function PuntoODG ($resource) {
        var resourceUrl =  'api/punto-odgs/:idPODG';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { 
                method:'PUT',
                url: 'api/ordine-del-giornos' + '/:idODG' + '/punto-odgs'
             },
             'updateAll': { 
                method:'PUT',
                url: 'api/ordine-del-giornos' + '/:idODG' + '/punto-odgs/all',
                isArray: true
             },
            'save': {
                method: 'POST',
                url: 'api/ordine-del-giornos' + '/:idODG' + '/punto-odgs'
            },
            'findAllByIdODG': {
                method: 'GET',
                url: 'api/ordine-del-giornos' + '/:idODG' + '/punto-odgs',
                isArray: true
            },
            'delete': {
                method: 'DELETE',
                url: 'api/ordine-del-giornos' + '/:idODG' + '/punto-odgs' + '/:idPuntoODG'
            }
        });
    }
})();
