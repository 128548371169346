(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('OrdineDelGiornoDeleteController',OrdineDelGiornoDeleteController);

    OrdineDelGiornoDeleteController.$inject = ['$uibModalInstance', '$state', 'toastr', 'entity', 'workingBody', 'OrdineDelGiorno'];

    function OrdineDelGiornoDeleteController($uibModalInstance, $state, toastr, entity, workingBody, OrdineDelGiorno) {
        var vm = this;

        vm.ordineDelGiorno = entity;
        vm.workingBody = workingBody;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            OrdineDelGiorno.delete({id: vm.ordineDelGiorno.id}, vm.ordineDelGiorno, function () {
                toastr.success("Ordine del Giorno eliminato con successo!");
    
                $state.go('seduta', {idOrg: vm.workingBody.id, typeOrg: vm.workingBody.tipoOrganismo, nameOrg: vm.workingBody.nome}, {reload: true}).then(function () {
                    $uibModalInstance.close(true)
                });
            });
        }
    }
})();
