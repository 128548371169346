(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SedutaDetailController', SedutaDetailController);

    SedutaDetailController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'toastr', 'previousState', 'entity', 'Seduta', 'SedutaCommonService', 'workingBody', 
    'organismiSeduta', 'docTypes', 'Commissione', 'SoggettoEsterno', 'OrdineDelGiorno', 'FileSaver', 'legislature', '$mdDialog', '$mdSidenav'];

    function SedutaDetailController($scope, $rootScope, $state, $timeout, toastr, previousState, entity, Seduta, SedutaCommonService, workingBody, 
        organismiSeduta, docTypes, Commissione, SoggettoEsterno, OrdineDelGiorno, FileSaver, legislature, $mdDialog, $mdSidenav) {
        var vm = this;

        vm.seduta = entity;
        vm.previousState = previousState.name;
        vm.previousStateParams = previousState.params;
        vm.workingBody = workingBody;
        vm.legislature = legislature;
        vm.showStateHistory;
        vm.isEditing = false;
        vm.isNumberEditing = false;
        vm.dateformat = "dd/MM/yyyy HH:mm";
        vm.ordiniDelGiorno = [];

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataDiConvocazioneEdit = false;
        vm.openCalendar = openCalendar;
        vm.prepareEditing = prepareEditing;
        vm.prepareNumberEditing = prepareNumberEditing;
        vm.saveSeduta = saveSeduta;
        vm.canEdit = canEdit;
        vm.canEditNumber = canEditNumber;
        vm.performButtonAction = performButtonAction;
        vm.generateAffariTrattatiDocument = generateAffariTrattatiDocument;
        vm.generateAffariTrattatiPDFReport = generateAffariTrattatiPDFReport;
        vm.toggleEsitoPublish = toggleEsitoPublish;
        
        initController();

        var unsubscribe = $rootScope.$on('gestioneAttiApp:sedutaUpdate', function(event, result) {
            vm.seduta = result;
        });
        $scope.$on('$destroy', unsubscribe);

        var confirmTransitionDlg = $mdDialog.confirm()
                .title('Attenzione!')
                .textContent("L'operazione richiesta non è reversibile, sei sicuro di voler procedere?")
                .ariaLabel('conferma transizione di stato')
                .ok('Conferma')
                .cancel('Annulla');

        function initController(){

            vm.organismiSeduta = organismiSeduta;
            if(SedutaCommonService.isOrganizzatore(vm.seduta, vm.workingBody)) {
                SedutaCommonService.removeWorkingBodyFromOrganismiSeduta(vm.organismiSeduta, vm.workingBody);
            }
            
            if(!workingBody.nome) {
                if(workingBody.tipoOrganismo === 'C') {
                    Commissione.get({id: vm.workingBody.id}, {}, function(result) {
                        vm.workingBody.nome = result.nome;
                    });
                } else if(workingBody.tipoOrganismo === 'E') {
                    SoggettoEsterno.get({id: vm.workingBody.id}, {}, function(result) {
                        vm.workingBody.nome = result.nome;
                    });
                }
            }
            vm.seduta.partecipantiSeduta = SedutaCommonService.getPartecipantiSeduta(vm.seduta);
            SedutaCommonService.fromSedutaBEToSedutaView(vm.seduta, vm.workingBody, vm.organismiSeduta);

            //Recupero ordini del giorno seduta
            OrdineDelGiorno.getAllByIdSeduta({idSeduta: vm.seduta.id},
                function (data) {
                    vm.ordiniDelGiorno = data;
                },
                onError);

            function onError(error) {
                toastr.error(error.data.message);
            }

            vm.canSeeEditButton = vm.canEdit(vm.seduta);
            vm.canSeeEditNumberButton = vm.canEditNumber(vm.seduta);

            initButtons();

            vm.docTypes = docTypes;
            
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function prepareEditing(){
            vm.isEditing = !vm.isEditing;
            vm.canSeeEditButton = !vm.isEditing && vm.canEdit(vm.seduta);
            initButtons();
        }

        function prepareNumberEditing(){
            vm.isNumberEditing = !vm.isNumberEditing;
            vm.canSeeEditNumberButton = !vm.isNumberEditing && vm.canEditNumber(vm.seduta);
        }

        function saveSeduta(){
            if(SedutaCommonService.fromSedutaViewToSedutaBE(vm.seduta, vm.workingBody)){
                vm.canSeeEditButton = vm.canEdit(vm.seduta);
                vm.canSeeEditNumberButton = vm.canEditNumber(vm.seduta);
                if (vm.seduta.id !== null) {
                    Seduta.update({typeOrg: vm.workingBody.tipoOrganismo, idOrg: vm.workingBody.id }, vm.seduta, onSaveSuccess, onSaveError);
                } else {
                    Seduta.saveWithWB({typeOrg: vm.workingBody.tipoOrganismo, idOrg: vm.workingBody.id }, vm.seduta, onSaveSuccess, onSaveError);
                }
            }
            
        }

        function onSaveSuccess(result) {
            toastr.success("Seduta salvata con successo");
            vm.isEditing = false;
            vm.isNumberEditing = false;
            reloadPage();
        }

        function onSaveError(error) {
            toastr.error("Errore nel salvataggio della seduta.");
            vm.isEditing = false;
            vm.isNumberEditing = false;
        }

        function canEdit(seduta){
            return SedutaCommonService.canEditSeduta(seduta, vm.workingBody);
        }

        function canEditNumber(seduta){
            return SedutaCommonService.canEditSedutaNumber(seduta, vm.workingBody);
        }

        function initButtons(){
            vm.visibleButtons = [];

            if(SedutaCommonService.isOrganizzatore(vm.seduta, vm.workingBody)) {

                if(vm.seduta.statoCorrente.statoSeduta === 'A' && vm.isEditing) {
                    vm.visibleButtons.push(
                            {
                                title: "entity.action.publishSeduta",
                                action: publishSeduta,
                                iconClass: "glyphicon-send",
                                nextStateName : "P"
                            },
                            {
                                title: "entity.action.revocationSeduta",
                                action: revokeSeduta,
                                iconClass: "glyphicon-thumbs-down",
                                nextStateName : "X"
                            }
                    );
                } else if(vm.seduta.statoCorrente.statoSeduta === 'P') {
                    vm.visibleButtons.push(
                            {
                                title: "entity.action.revocationSeduta",
                                action: revokeSeduta,
                                iconClass: "glyphicon-thumbs-down",
                                nextStateName : "X"
                            }
                    );
                }
            }

        }

        function publishSeduta(newState){
            $mdDialog.show(confirmTransitionDlg).then(function () {
                transitionTo('P');
            });
        }

        function revokeSeduta(newState){
            $mdDialog.show(confirmTransitionDlg).then(function () {
                transitionTo('X');
            });
        }

        function transitionTo(newState) {
            Seduta.sedutaStateTransition({id: vm.seduta.id, nextStateName: newState} , null , function (success) {
                if(newState === 'P') {
                    toastr.success('Seduta <b>aggiornata</b> con successo', 'Okay!');
                } else if( newState === 'X') {
                    toastr.success('Seduta <b>revocata</b> con successo', 'Okay!');
                }
                reloadPage();
            }, function (error) {
                toastr.error('Impossibile eseguire il passaggo allo stato <b>' + newState + '</b>:<br/>' + error.data.message, 'Errore!');
            });
        }

        function performButtonAction(button){
            if(button.action){
                button.action( button.nextStateName);
            }
        }

        function reloadPage() {
            $timeout(function () {
                $state.reload();
            }, 0);
        }

        function generateAffariTrattatiDocument(){         
            vm.documentParams = {
                legislatura: vm.seduta.legislatura,
                numeroSeduta: vm.seduta.numeroSeduta,
                idSeduta: vm.seduta.id
            }

            Seduta.generateAffariTrattatiDocument({}, vm.documentParams, onSuccess, onError);

            function onSuccess(result) {
                toastr.success('Documento ODG scaricato con successo nella cartella Download!');
            };
            function onError(error) {
                console.log(error);
            }
        }

        function generateAffariTrattatiPDFReport() {
            Seduta.getAffariTrattatiPDFReport(vm.seduta.id).then(
                function(result) {
                    FileSaver.saveAs(new Blob([result.data], {}), "reportAffariTrattati.pdf");
                }, 
                function(error) {
                    var errorCode = error.headers('X-gestioneAttiApp-error');
                    var errorText = '';
                    if(errorCode === 'error.noContent') {
                        errorText = 'Nessun affare trattato e\' stato trovato per questa seduta';
                    } else if(errorCode === 'error.forbiddenOp') {
                        errorText = 'La tua utenza non e\' abilitata alla generazione di report. Si prega di contattare l\'amministratore'
                    } else {
                        errorText = 'Errore inaspettato. Si prega di riprovare o contattare l\'amministratore!'
                    }
                    toastr.error(errorText, "Errore!", {
                        timeOut: 60000,
                        extendedTimeOut: 0
                    });
                });
        }
        function toggleEsitoPublish () {
            $mdSidenav('sedutaEsitoPublish').toggle();
        }

    }
})();
