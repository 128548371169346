(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('SoggettoEsternoDialogController', SoggettoEsternoDialogController);

    SoggettoEsternoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SoggettoEsterno'];

    function SoggettoEsternoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SoggettoEsterno) {
        var vm = this;

        vm.soggettoEsterno = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.soggettoEsterno.id !== null) {
                SoggettoEsterno.update(vm.soggettoEsterno, onSaveSuccess, onSaveError);
            } else {
                SoggettoEsterno.save(vm.soggettoEsterno, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('gestioneAttiApp:soggettoEsternoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
