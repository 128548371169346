(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('punto-odg-detail', {
            parent: 'ordine-del-giorno',
            url: '/punto-odg/{idPODG}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gestioneAttiApp.puntoODG.detail.title'
            },
            params:  {
                idAttoCollegato: {
                    value: null
                },
                editODG: {
                    value: null
                }
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/punto-odg/punto-odg-dialog.html',
                    controller: 'PuntoODGDialogController',
                    controllerAs: 'vm',
                    bindToController: true,
                    fullscreen: true,
                    resolve: {
                        entity: ['PuntoODG', function(PuntoODG) {
                            return PuntoODG.get({idPODG : $stateParams.idPODG}).$promise;
                        }],
                        odg: ['OrdineDelGiorno', function(OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['Seduta', function(Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }],
                        editingMode: [function () {
                            return false;
                        }],
                        editODG: [function () {
                            return $stateParams.editODG;
                        }],
                        workingBody: [function() {
                            return {
                                id: $stateParams.idOrg,
                                tipoOrganismo: $stateParams.typeOrg,
                                nome: $stateParams.nameOrg
                            }
                        }],
                        title: [function() {
                            return "Visualizza il Punto ODG";
                        }],
                        attoCollegato: ['Atto', function(Atto) {
                            if($stateParams.idAttoCollegato) {
                                return Atto.get({id : $stateParams.idAttoCollegato}).$promise;
                            }
                        }],
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'ordine-del-giorno',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('puntoODG');
                            $translatePartialLoader.addPart('tipoPuntoODG');
                            $translatePartialLoader.addPart('espressioneRichiesta');
                            $translatePartialLoader.addPart('esitoPuntoODG');
                            return $translate.refresh();
                        }]
                    }
                });
            }]
        })
        .state('punto-odg-detail.edit', {
            parent: 'ordine-del-giorno',
            url: '/punto-odg/{idPODG}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            params:  {
                idAttoCollegato: {
                    value: null
                },
                editODG: {
                    value: null
                }
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/punto-odg/punto-odg-dialog.html',
                    controller: 'PuntoODGDialogController',
                    controllerAs: 'vm',
                    bindToController: true,
                    fullscreen: true,
                    resolve: {
                        entity: ['PuntoODG', function(PuntoODG) {
                            return PuntoODG.get({idPODG : $stateParams.idPODG}).$promise;
                        }],
                        odg: ['OrdineDelGiorno', function(OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['Seduta', function(Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }],
                        editingMode: [function () {
                            return true;
                        }],
                        editODG: [function () {
                            return $stateParams.editODG;
                        }],
                        workingBody: ['$stateParams', function($stateParams) {
                            return {
                                id: $stateParams.idOrg,
                                tipoOrganismo: $stateParams.typeOrg,
                                nome: $stateParams.nameOrg
                            }
                        }],
                        title: [function() {
                            return "Modifica il Punto ODG";
                        }],
                        attoCollegato: ['Atto', function(Atto) {
                            if($stateParams.idAttoCollegato) {
                                return Atto.get({id : $stateParams.idAttoCollegato}).$promise;
                            }
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('puntoODG');
                            $translatePartialLoader.addPart('tipoPuntoODG');
                            $translatePartialLoader.addPart('espressioneRichiesta');
                            $translatePartialLoader.addPart('esitoPuntoODG');
                            return $translate.refresh();
                        }]
                    }
                }).then(function() {
                    $state.go('ordine-del-giorno.edit', {
                        odg: ['$stateParams', 'OrdineDelGiorno', function($stateParams, OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['$stateParams', 'Seduta', function($stateParams, Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }]
                    }, { reload: 'ordine-del-giorno.edit' });
                }, function() {
                    $state.go('ordine-del-giorno.edit');
                });
            }]
        })
        .state('punto-odg-dialog', {
            parent: 'ordine-del-giorno',
            url: '/punto-odg-new',
            data: {
                authorities: ['ROLE_USER']
            },
            params:  {
                idAttoCollegato: {
                    value: null
                },
                editODG: {
                    value: null
                }
            },
            onEnter: ['$stateParams', '$state', '$mdDialog', function($stateParams, $state, $mdDialog) {
                $mdDialog.show({
                    templateUrl: 'app/entities/punto-odg/punto-odg-dialog.html',
                    controller: 'PuntoODGDialogController',
                    controllerAs: 'vm',
                    bindToController: true,
                    fullscreen: true,
                    locals: {
                        entity: {
                            tipoPuntoODG: null,
                            idAtto: null,
                            testoPuntoODG: null,
                            tipoEspressioneRichiesta: null,
                            progressivoPuntoODG: null,
                            esitoPuntoODG: null,
                            descrizioneEsitoPuntoODG: null,
                            id: null
                        }
                    },
                    resolve: {
                        odg: ['OrdineDelGiorno', function(OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['Seduta', function(Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }],
                        editingMode: [function () {
                            return true;
                        }],
                        editODG: [function () {
                            return $stateParams.editODG;
                        }],
                        attoCollegato: [ function() {
                            return null;
                        }],
                        workingBody: ['$stateParams', function($stateParams) {
                            return {
                                id: $stateParams.idOrg,
                                tipoOrganismo: $stateParams.typeOrg,
                                nome: $stateParams.nameOrg
                            }
                        }],
                        title: [function() {
                            return "Crea un nuovo Punto ODG";
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('puntoODG');
                            $translatePartialLoader.addPart('tipoPuntoODG');
                            $translatePartialLoader.addPart('espressioneRichiesta');
                            $translatePartialLoader.addPart('esitoPuntoODG');
                            return $translate.refresh();
                        }]
                    }
                }).then(function() {
                    $state.go('ordine-del-giorno.edit', {
                        odg: ['$stateParams', 'OrdineDelGiorno', function($stateParams, OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['$stateParams', 'Seduta', function($stateParams, Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }]
                    }, { reload: true });
                }, function() {
                    $state.go('ordine-del-giorno.edit');
                });
            }]
        })
        .state('punto-odg-dialog.delete', {
            parent: 'ordine-del-giorno',
            url: '/punto-odg/{idPODG}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            params:  {
                idPODG: {
                    value: null
                }
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/punto-odg/punto-odg-delete-dialog.html',
                    controller: 'PuntoODGDeleteController',
                    controllerAs: 'vm',
                    resolve: {
                        puntiODG: ['PuntoODG', function(PuntoODG) {
                            return PuntoODG.findAllByIdODG({idODG: $stateParams.idODG}).$promise;
                        }],
                        puntoODGToDelete: ['PuntoODG', function(PuntoODG) {
                            return PuntoODG.get({idPODG: $stateParams.idPODG}).$promise;
                        }] 
                    }
                }).result.then(function() {
                    $state.go('ordine-del-giorno.edit', {
                        odg: ['$stateParams', 'OrdineDelGiorno', function($stateParams, OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['$stateParams', 'Seduta', function($stateParams, Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }]
                    }, { reload: 'ordine-del-giorno.edit' });
                }, function() {
                    $state.go('ordine-del-giorno.edit');
                });
            }]
        })
        .state('punto-odg-dialog.copy', {
            parent: 'ordine-del-giorno',
            url: '/punto-odg-copy-dettaglio',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                numeroSeduta: {
                    value: null
                }
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/punto-odg/punto-odg-copy-dialog.html',
                    controller: 'PuntoODGCopyController',
                    controllerAs: 'vm',
                    resolve: {
                        puntiODG: ['PuntoODG', function(PuntoODG) {
                            return PuntoODG.findAllByIdODG({idODG: $stateParams.idODG}).$promise;
                        }],
                        odg: ['OrdineDelGiorno', function(OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['Seduta', function(Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ordine-del-giorno.edit', {
                        odg: ['OrdineDelGiorno', function(OrdineDelGiorno) {
                            return OrdineDelGiorno.get({id : $stateParams.idODG}).$promise;
                        }],
                        seduta: ['Seduta', function(Seduta) {
                            return Seduta.get({id : $stateParams.idSeduta}).$promise;
                        }]
                    }, { reload: 'ordine-del-giorno.edit' });
                }, function() {
                    $state.go('ordine-del-giorno.edit');
                });
            }]
        });
    }

})();
