(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('seduta', {
            parent: 'entity',
            url: '/seduta/:typeOrg/:idOrg?page&sort&search&legislatura&anno&minNum&maxNum&minDataConv&maxDataConv&testoOdg',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gestioneAttiApp.seduta.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/seduta/sedutas.html',
                    controller: 'SedutaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'dataDiConvocazione,desc',
                    squash: false
                },
                search: null,
                legislatura : {
                    value: '11',
                    squash: false
                },
                anno : {
                    value: null,
                    squash: false
                },
                idOrg: {
                    value: null,
                    squash: false
                },
                typeOrg: {
                    value: null,
                    squash: false
                },
                nameOrg: {
                    value: null
                },
                minNum: {
                    value: null,
                    squash: true
                    
                },
                maxNum: {
                    value: null,
                    squash: true
                },
                minDataConv: {
                    value: null,
                    squash: true
                },
                maxDataConv: {
                    value: null,
                    squash: true
                },
                testoOdg: {
                    value: null,
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        legislatura: $stateParams.legislatura,
                        anno: $stateParams.anno,
                        minNum: $stateParams.minNum,
                        maxNum: $stateParams.maxNum,
                        minDataConv: $stateParams.minDataConv,
                        maxDataConv: $stateParams.maxDataConv,
                        testoOdg: $stateParams.testoOdg
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('seduta');
                    $translatePartialLoader.addPart('tipoSeduta');
                    $translatePartialLoader.addPart('statoSeduta');
                    $translatePartialLoader.addPart('tipoOrganizzatore');
                    $translatePartialLoader.addPart('ordineDelGiorno');
                    $translatePartialLoader.addPart('addable-input');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                legislature : [ 'Atto', function(Atto){
                    return Atto.queryLegislature({}).$promise;
                }],
                workingBody: ['$stateParams', function($stateParams) {
                    return {
                        id: $stateParams.idOrg,
                        tipoOrganismo: $stateParams.typeOrg,
                        nome: $stateParams.nameOrg
                    }
                }],
            },
        })
        .state('seduta-detail', {
            parent: 'seduta',
            url: '/{id}/detail',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'gestioneAttiApp.seduta.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/seduta/seduta-detail.html',
                    controller: 'SedutaDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                nameOrg: {
                    value: null,
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('seduta');
                    $translatePartialLoader.addPart('tipoSeduta');
                    $translatePartialLoader.addPart('statoSeduta');
                    $translatePartialLoader.addPart('tipoOrganizzatore');
                    $translatePartialLoader.addPart('statoODGType');
                    $translatePartialLoader.addPart('document-toolbar');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Seduta', function($stateParams, Seduta) {
                    return Seduta.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'seduta',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                legislature : [ 'Atto', function(Atto){
                    return Atto.queryLegislature({}).$promise;
                }],
                organismiSeduta: [ 'Seduta', function(Seduta) {
                    return Seduta.organismiSeduta({}).$promise;
                }],
                docTypes : [ 'TipoDocumento', function(TipoDocumento) {
                    return TipoDocumento.tipiDocumentoSeduta({}).$promise;
                }],
                workingBody: ['$stateParams', 'SedutaCommonService', function($stateParams, SedutaCommonService) {
                    return {
                        id: $stateParams.idOrg,
                        tipoOrganismo: $stateParams.typeOrg,
                        nome: $stateParams.nameOrg
                    }
                }],
            }
        })
        .state('seduta-detail.edit', {
            parent: 'seduta-detail',
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams','$mdDialog', function($stateParams, $mdDialog) {
                $mdDialog.show({
                    clickOutsideToClose: false,
                    fullscreen: true,
                    templateUrl: 'app/entities/seduta/seduta-dialog.html',
                    controller: 'SedutaDialogController',
                    controllerAs: 'vm',
                    bindToController: true,
                    locals: {
                        entity: {
                            id: null,
                            legislatura: null,
                            tipoSeduta: null,
                            modalitaSeduta: null,
                            statoSeduta: null,
                            tipoOrganizzatore: null,
                            idOrganizzatore: null,
                            numeroSeduta: null,
                            dataDiConvocazione: null,
                            partecipanti: []
                        }
                    },
                    resolve: {
                        entity: ['Seduta', function(Seduta) {
                            return Seduta.get({id : $stateParams.id}).$promise;
                        }],
                        workingBody: ['$stateParams', function($stateParams) {
                            return {
                                id: $stateParams.idOrg,
                                tipoOrganismo: $stateParams.typeOrg,
                                nome: $stateParams.nome,
                                legislatura: $stateParams.legislatura
                            }
                        }],
                        legislature : [ 'Atto', function(Atto){
                            return Atto.queryLegislature({}).$promise;
                        }],
                        nextNumeroSeduta : [ '$stateParams', 'Seduta', function( $stateParams, Seduta) {
                            return Seduta.nextNumeroSeduta({idOrg : $stateParams.idOrg, typeOrg : $stateParams.typeOrg, legislatura : $stateParams.legislatura, anno : $stateParams.anno}).$promise;
                        }],
                        organismiSeduta: [ 'Seduta', function(Seduta) {
                            return Seduta.organismiSeduta({}).$promise;
                        }]
                    }
                });
            }]
        })
        .state('seduta.new', {
            parent: 'seduta',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                idOrg: {
                    value: null
                },
                typeOrg: {
                    value: null
                },
                nameOrg: {
                    value : null
                },
                legislatura : {
                    value : null
                },
                anno: {
                    value: null
                }
            },
            onEnter: ['$mdDialog', function ($mdDialog) {
                $mdDialog.show({
                    clickOutsideToClose: false,
                    fullscreen: true,
                    templateUrl: 'app/entities/seduta/seduta-dialog.html',
                    controller: 'SedutaDialogController',
                    controllerAs: 'vm',
                    bindToController: true,
                    locals: {
                        entity: {
                            id: null,
                            legislatura: null,
                            anno: null,
                            tipoSeduta: null,
                            modalitaSeduta: null,
                            statoCorrente: {statoSeduta : 'A'},
                            tipoOrganizzatore: null,
                            idOrganizzatore: null,
                            numeroSeduta: null,
                            dataDiConvocazione: null,
                            partecipanti: []
                        }
                    },
                    resolve: {
                        workingBody: ['$stateParams', function($stateParams) {
                            return {
                                id: $stateParams.idOrg,
                                tipoOrganismo: $stateParams.typeOrg,
                                nome: $stateParams.nameOrg,
                                legislatura: $stateParams.legislatura
                            }
                        }],
                        anno: ['$stateParams', function($stateParams) {
                            return parseInt($stateParams.anno, 10);
                        }],
                        legislature : [ 'Atto', function(Atto){
                            return Atto.queryLegislature({}).$promise;
                        }],
                        nextNumeroSeduta : [ '$stateParams', 'Seduta', function( $stateParams, Seduta) {
                            return Seduta.nextNumeroSeduta({idOrg : $stateParams.idOrg, typeOrg : $stateParams.typeOrg, legislatura : $stateParams.legislatura, annoSeduta : $stateParams.anno}).$promise;
                        }],
                        organismiSeduta: [ 'Seduta', function(Seduta) {
                            return Seduta.organismiSeduta({}).$promise;
                        }]
                    }
                });
            }]
        })
        .state('seduta.edit', {
            parent: 'seduta',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$mdDialog', '$state', 'Seduta', function($stateParams, $mdDialog, $state, Seduta) {
                $mdDialog.show({
                    clickOutsideToClose: false,
                    fullscreen: true,
                    templateUrl: 'app/entities/seduta/seduta-dialog.html',
                    controller: 'SedutaDialogController',
                    controllerAs: 'vm',
                    bindToController: true,
                    locals: {
                        entity: Seduta.get({id: $stateParams.id}).$promise,
                        nextNumeroSeduta : null
                    },
                    resolve: {
                        workingBody: ['$stateParams', function($stateParams) {
                            return {
                                id: $stateParams.idOrg,
                                tipoOrganismo: $stateParams.typeOrg,
                                nome: $stateParams.nome,
                                legislatura: $stateParams.legislatura
                            }
                        }],
                        legislature : [ 'Atto', function(Atto){
                            return Atto.queryLegislature({}).$promise;
                        }],
                        organismiSeduta: [ 'Seduta', function(Seduta) {
                            return Seduta.organismiSeduta({}).$promise;
                        }]
                    }
                }).then(function (result) {
                    $state.go('seduta', null, {reload: true});
                  },
                  function () {
                    $state.go('seduta', null, {reload: false});
                  });
            }]
        })
        .state('seduta.delete', {
            parent: 'seduta',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/seduta/seduta-delete-dialog.html',
                    controller: 'SedutaDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Seduta', function(Seduta) {
                            return Seduta.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('seduta', null, { reload: 'seduta' });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('seduta.select-working', {
            parent: 'app',
            url: '/select-working?legislatura',
            data: {
                authorities: [],
                pageTitle: 'gestioneAttiApp.seduta.home.title'
            },
            onEnter: ['$mdDialog', function ( $mdDialog) {
                $mdDialog.show({
                    clickOutsideToClose: false,
                    templateUrl: 'app/entities/seduta/seduta-select-working-dialog.html',
                    controller: 'SedutaSelectWorkingController',
                    controllerAs: 'vm',
                    bindToController: true,
                    params: {
                        legislatura : {
                            value : 11,
                            squash: true
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('atto');
                                $translatePartialLoader.addPart('seduta');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('form');
                                return $translate.refresh();
                        }],
                        legislature : [ 'Atto', function(Atto){
                            return Atto.queryLegislature({}).$promise;
                        }],
                        legislatura : ['$stateParams', function($stateParams) {
                            return $stateParams.legislatura;
                        }]
                    }
                });
            }]
      }).state('seduta-detail.copy', {
        parent: 'seduta-detail',
        url: '/seduta-copy-dettaglio',
        data: {
            authorities: ['ROLE_USER']
        },
        params: {
            numeroSeduta: {
                value: null
            },
            seduta : {
                value : null
            }
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/seduta/seduta-copy-dialog.html',
                controller: 'SedutaCopyController',
                controllerAs: 'vm',
                resolve: {
                    puntiODG: ['Seduta', function(Seduta) {
                        return Seduta.getPuntiAffariTrattatiSeduta({id: $stateParams.id}).$promise;
                    }]
                }
            }).result.then(function() {
                $state.go('seduta-detail', {
                    
                }, { reload: 'seduta-detail' });
            }, function() {
                $state.go('seduta-detail');
            });
        }]
    });;
    }

})();
