(function() {
    'use strict';

    angular
        .module('gestioneAttiApp')
        .controller('DirezioneController', DirezioneController);

    DirezioneController.$inject = ['Direzione'];

    function DirezioneController(Direzione) {

        var vm = this;

        vm.direziones = [];

        loadAll();

        function loadAll() {
            Direzione.query(function(result) {
                vm.direziones = result;
                vm.searchQuery = null;
            });
        }
    }
})();
