(function () {
    'use strict';
    angular
            .module('gestioneAttiApp')
            .factory('Documento', Documento);

    Documento.$inject = ['$resource', '$http'];

    function Documento($resource, $http) {
        var resourceUrl = 'api/:public/documenti/:id/:content/:idSezione/:group/:retryProtocol';

        var resource = $resource(resourceUrl, {id: '@id'}, {
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'retryProtocol': {
                method: 'POST',
                params: {
                    retryProtocol: 'retryProtocol'
                }
            },
            'getDocumentiEmendamento': {
                url: 'api/public/documenti/emendamenti/:sezioneId',
                method: 'GET',
                isArray: true
            },
            'getDocumentiBySezioneId': {
                url: 'api/documenti/sezione/:sezioneId',
                method: 'GET',
                isArray: true
            },
            'getDocumentiDiRitiroAtto': {
                url: 'api/public/documenti/:idAtto/:mnemonic',
                method: 'GET',
                isArray: true
            },
            'addOrUpdateDocumentCandidaturaDesignazione': {
                url: 'api/documenti/:documentId/candidatureDesignazioni',
                method: 'PUT'
            },
        });

        resource.getContent = function (id) {
            return $http.get('api/public/documenti/' + id + '/content', {
                responseType: 'arraybuffer'
            });

        };

        resource.getDocSeduta = function (id) {
            return $http.get('api/public/documenti-seduta/' + id , {});
        };

        resource.getDocSedutaContent = function (id) {
            return $http.get('api/public/documenti-seduta/' + id + '/content', {
                responseType: 'arraybuffer'
            });

        };

        resource.deleteDocSedutaContent = function (id) {
            return $http.delete('api/documenti-seduta/' + id );
        };

        resource.getGroupArchive = function (idSezione, group, publicView) {
            return $http.get('api/public/documenti/' + idSezione + '/' + group + '/content', {
                responseType: 'arraybuffer',
                params: {publicView: publicView}
            });

        };

        resource.retryProtocolSedutaOdg = function(idSeduta, idOdgDoc){
            return $http.post('api/documenti-seduta/' + idSeduta + "/" + idOdgDoc + "/retryProtocol", {}, {});
        };

        return resource;
    }
})();
