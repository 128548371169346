(function() {
    'use strict';
    angular
        .module('gestioneAttiApp')
        .factory('RegistrazioneUtente', RegistrazioneUtente);

        RegistrazioneUtente.$inject = ['$resource'];

    function RegistrazioneUtente ($resource) {
        var resourceUrl =  'api/registrazioni-utente';

        var resource = $resource(resourceUrl, {}, {
            'createOrUpdate': {
                method: 'POST',
                url: 'api/public/registrazioni-utente/atto'
            },
            'get': {
                method: 'GET',
                params: {public: 'public'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                url: resourceUrl + '/:id'
            },
            'delete': {
                method: 'DELETE',
                url: resourceUrl + '/:id'
            },
            'deleteRegistrazioneAtto': {
                method: 'PUT',
                url: 'api/public/registrazioni-utente' + '/:id' + '/registrazioni-atto/remove'
            },
            'getDettaglioRegistrazioneUtenteByAttoIdAndTipoAtto': {
                method: 'GET',
                url: 'api/public/registrazioni-utente/dettaglio-registrazione' + '/:id'
            }
        });

        return resource;
    }
})();
